import React, { useState } from "react";

import { AvatarGroupDatas } from "assets/data/data";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import AvatarComponent from "components/Common/AvatarComponent";
import ComponentCard from "components/Common/ComponentCard";
import CommonModal from "components/Common/CommonModal";
import CustomSelect from "components/Common/CustomSelect";
import { EditIcon } from "components/Icons";

import "./PreviewAvatarGroupComponent.scss";
import { avatarGroupCode } from "./avatarGroupCode";

const PreviewAvatarGroupComponent = ({
  tabData,
  handleTabClick,
  activeTab,
  setSelectedTab,
}) => {
  const previewavatargroupData = [
    {
      id: 1,
      title: "Normal",
      description: "People are indicated with an image in a circle.",
      data: [
        { key: 1, type: "image" },
        { key: 2, type: "image" },
        { key: 3, type: "image" },
        { key: 4, type: "image" },
        { key: 5, type: "image" },
        { key: 6, type: "image" },
        { key: 7, type: "image" },
      ],
    },
  ];

  const [isModalVisible, setModalVisibility] = useState(false);
  const showModal = () => {
    setModalVisibility(true);
  };

  const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
  const [buttonState, setButtonState] = useState(buttonStates[0]);

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Default
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {AvatarGroupDatas.map((data, index) => (
          <div key={index} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              editClick={showModal}
              showCode={true}
              htmlContent={avatarGroupCode(data)}
            >
              <div className="plt-avatar-set">
                <AvatarComponent
                  size={data.size}
                  avatarData={data.data}
                  avatarGroup
                />
              </div>
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
      </div>
      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                />
              </div>
            </div>
          </div>

          <div className="plt-preview plt-heading-xl dis-mb-32">
            {previewavatargroupData.map((data, index) => (
              <AvatarComponent
                key={index}
                size="lg"
                avatarData={data.data}
                avatarGroup
              />
            ))}
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Padding
            </h6>
            <div className="plt-token-value-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">H:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">V:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>

              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Radius
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                radius-150
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => setRadiusTokenModalOpen(true)}
              >
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewAvatarGroupComponent;
