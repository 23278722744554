import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import SideNavTabs from "components/DesignSystemPage/SideNavTabs";
import { getUser } from "hooks/storageUtils";
import { PrimaryLogo } from "components/Icons";
import UserLogo from "components/user/UserLogo";
import { useParams } from "react-router-dom";
import SettingsHeader from "./SettingsHeader";

const SettingsLayout = ({
	children,
	sideTabs,
	title = "Profile settings",
	isModalOpen,
	setModalOpen,
	canAddMember,
	setCanAddMember,
	isUpgradePlanAlertModalOpen,
	setUpgradePlanAlertModalOpen,
	canCreateDesignSystem,
	setCanCreateDesignSystem,
	isCreateDSModalOpen,
	setCreateDSModalOpen,
}) => {
	const { id } = useParams();
	const user = getUser();

	const defaultLogo = {
		component: <PrimaryLogo />,
		text: "Design",
	};

	const userLogo = {
		component: <UserLogo user={user} />,
		text: "Design",
	};

	const [companyLogo, setCompanyLogo] = useState(user?.company_logo ? userLogo : defaultLogo);

	useEffect(() => {
		setCompanyLogo(user?.company_logo ? userLogo : defaultLogo);
	}, [user.logo_url]);

	const defaultSideTabs = [
		{ title: "My account", link: `/${id}/settings` },
		{
			title: "Oraganization settings",
			link: `/${id}/settings`,
			disabled: true,
		},
		{ title: "General", link: `/${id}/general` },
		{ title: "Subscription", link: `/${id}/subscriptions` },
		{ title: "Design systems", link: `/${id}/designsystems` },
		{ title: "Team", link: `/${id}/teams` },
		// { title: "Email notifications", link: `/${id}/email-notifications` },
	];
	const activeTab = defaultSideTabs.find((tab) => location.pathname.includes(tab.link));
	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				isModalOpen,
				setModalOpen,
				canAddMember,
				setCanAddMember,
				isUpgradePlanAlertModalOpen,
				setUpgradePlanAlertModalOpen,
				canCreateDesignSystem,
				setCanCreateDesignSystem,
				isCreateDSModalOpen,
				setCreateDSModalOpen,
			});
		}
		return child; // Return unmodified child if it's not a valid React element
	});
	return (
		<div className="dss-page d-flex h-full">
			<ToastContainer />
			<SideNavTabs tabs={defaultSideTabs} logo={companyLogo} title={title} />
			<div className="tab-content-body w-100vw">
				<SettingsHeader
					user={user}
					activeTab={activeTab}
					isModalOpen={isModalOpen}
					setModalOpen={setModalOpen}
					canAddMember={canAddMember}
					setCanAddMember={setCanAddMember}
					isUpgradePlanAlertModalOpen={isUpgradePlanAlertModalOpen}
					setUpgradePlanAlertModalOpen={setUpgradePlanAlertModalOpen}
					canCreateDesignSystem={canCreateDesignSystem}
					setCanCreateDesignSystem={canCreateDesignSystem}
					isCreateDSModalOpen={canCreateDesignSystem}
					setCreateDSModalOpen={setCreateDSModalOpen}
				/>
				{childrenWithProps}
			</div>
		</div>
	);
};

export default SettingsLayout;
