import React, { useState, useRef, useCallback } from "react";

import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";

import { getStylesSideTabData } from "assets/data/sideTabData";
import colorSectionData from "./colorSectionData";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import TabNavigation from "components/DesignSystemPage/TabNavigation";
import useScrollManagement from "hooks/useScrollManagement";
import ColorPickerComponent from "components/DesignSystemPage/ColorPickerComponent";
import TypoUsage from "components/DesignSystemPage/Typography/Usage";

import "assets/scss/pages/DesignSystemColors.scss";
const ColorSectionComponent = ({ title, description, colorData }) => {
  const renderColorData = useCallback(
    () =>
      colorData?.map((color, index) => (
        <React.Fragment key={index}>
          {color.subTitle && (
            <p className="plt-subtitle plt-heading-xs plt-font-color-primary dis-mb-8">
              {color.subTitle}
            </p>
          )}
          <ColorPickerComponent
            baseColor={color.baseColor}
            shades={color.shades}
            tokenType={color.tokenType}
          />
        </React.Fragment>
      )),
    [colorData]
  );

  return (
    <div className="plt-content-wrapper dis-mb-48">
      {title && (
        <h6 className="plt-contents-head plt-heading-md plt-font-color-primary font-weight-600 dis-mb-8">
          {title}
        </h6>
      )}
      {description && (
        <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24">
          {description}
        </p>
      )}
      {renderColorData()}
    </div>
  );
};
const DesignSystemColors = () => {
  const colorSections = colorSectionData();
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();

  // const [activeTab, setActiveTab] = useState(0);
  const [activeSideTab, setActiveSideTab] = useState(0);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };

  const sectionRefs = colorSections?.map(() => useRef(null));
  const scrollToSection = useScrollManagement(
    sectionRefs,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index, sectionRefs);
  };
  const bannerData = {
    heading: "Colors",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Color", "Usage"];

  return (
    <DesignSystemLayout
      selectedTab={selectedTab}
      setSelectedTab={handleHeaderTabClick}
      bannerData={bannerData}
      tabs={tabs}
      sideTabs={getStylesSideTabData(id)}
      title="Foundation"
    >
      <div className="tab-content-wrapper d-flex justify-content-between">
        {selectedTab === 0 && (
          <div className="w-100 d-flex justify-content-between">
            <div className="tab-content-container">
              {colorSections?.map((section, index) => (
                <div ref={sectionRefs[index]} key={section.title}>
                  <ColorSectionComponent {...section} index />
                </div>
              ))}
            </div>
            <div className="plt-token-contents">
              <TabNavigation
                tabs={colorSections?.map((section) => section.title)}
                title={"CONTENTS"}
                activeIndex={activeSideTab}
                onTabClick={handleTabClick}
              />
            </div>
          </div>
        )}
        {selectedTab === 1 && <TypoUsage />}
      </div>
    </DesignSystemLayout>
  );
};

export default DesignSystemColors;
