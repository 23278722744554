import React, { useState } from "react";

import { useGlobalContext } from "context/GlobalContext";

import { AlertUsagePrimaryDatas } from "assets/data/data";

import AlertDetail from "assets/images/png/AlertDetail.png";
import alertInformative from "assets/images/png/alertInformative.png";
import alertCaution from "assets/images/png/alertCaution.png";
import alertSuccess from "assets/images/png/alertSuccess.png";
import alertDanger from "assets/images/png/alertDanger.png";
import alertPlacement from "assets/images/png/alertPlacement.png";
import alertSpacing from "assets/images/png/alertSpacing.png";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";
import GuidelineCardAlert from "components/Common/Guidelines/GuidelineCardAlert";

const details = [
  {
    number: 1,
    title: "Leading icon",
    description: "Icon placed before the input to provide context or guidance.",
  },
  {
    number: 2,
    title: "Lead heading (optional)",
    description: "Indicates the purpose or prompt for the text field.",
  },
  {
    number: 3,
    title: "Text",
    description: "Non-editable text appearing before the user's input.",
  },
  {
    number: 4,
    title: "Buttons",
    description: "The text entered or edited by the user.",
  },
  {
    number: 5,
    title: "Trailing icon",
    description:
      "Small interactive elements representing tags or selected items.",
  },
  {
    number: 6,
    title: "Container",
    description: "Non-editable text displayed after the user's input.",
  },
];

const UsageAlterComponent = () => {
  const tabData = [
    "Anatomy",
    "Types of Alerts",
    "Placement",
    "Spacing",
    "Guidelines",
  ];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              The checkbox component is comprised of a checkbox label and a
              checkbox input. If there is a group of checkboxes, a group label
              can be added.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={AlertDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map((detail) => (
              <DetailItem
                key={detail.number}
                number={detail.number}
                title={detail.title}
                description={detail.description}
              />
            ))}
          </ul>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[1]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Types of Alerts
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              Text fields are crucial components in user interfaces, allowing
              users to input and manage information seamlessly.
            </p>
          </div>

          {AlertUsagePrimaryDatas.map((data, index) => (
            <div key={index} className="dis-mb-40">
              <div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_1}
                  </h4>
                  <div className="plt-component-card">
                    <img src={alertInformative} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_2}
                  </h4>
                  <div className="plt-component-card">
                    <img src={alertCaution} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_3}
                  </h4>
                  <div className="plt-component-card">
                    <img src={alertSuccess} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_4}
                  </h4>
                  <div className="plt-component-card">
                    <img src={alertDanger} alt="userimage" />
                  </div>
                </div>
              </div>

              <p
                className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}
              >
                {data.detail}
              </p>
              {data.detail_list}
            </div>
          ))}

          <div className="dis-mt-40 dis-mb-40">
            <div className="plt-content-wrapper dis-mb-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[2]}
              >
                Placement
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Ensure the text field is left-aligned and properly indented.
                This alignment promotes consistency and enhances readability
                across the user interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={alertPlacement} alt="userimage" />
            </div>

            <div className="plt-content-wrapper dis-mb-24 dis-mt-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[3]}
              >
                Spacing
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Maintain consistent spacing around the text field to ensure it
                is visually distinct and easily accessible. Adequate spacing
                improves readability and prevents a cluttered interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={alertSpacing} alt="userimage" />
            </div>
          </div>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
              ref={sectionRefs.current[4]}
            >
              Guidelines
            </h6>
            <ul className="plt-detail-list dis-mt-12">
              <li className="plt-body-md plt-font-color-primary">
                Position labels to the left of text input fields, aligning them
                with the top of the fields.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Maintain uniform spacing between labels and input fields to
                ensure clarity and balance.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Align labels with the top edge of the text input fields for a
                clean and organized appearance.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Ensure labels are clearly associated with their corresponding
                input fields for improved usability.
              </li>
            </ul>
          </div>

          <GuidelineCardAlert />
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageAlterComponent;
