import { trimmedCode } from "helpers/code";

export const tooltipCode = (data, wrap = false) => {
	return trimmedCode(`<div class="dss-tooltip-container dss-ui-text-md dss-font-color-primary">
  Hover this
    <div class="dss-tooltip dss-tooltip-${data.position} dss-tooltip-${data.arrow} dss-tooltip-with-label ${data.size ? "dss-tooltip-large" : ""} ${wrap ? "dss-tooltip-wrap" : ""}">
      <h6 class="dss-tooltip-label">Label</h6>
      <h4 class="dss-tooltip-text">This is tooltip</h4>
    </div>
  </div>`);
};
