import React, { useState, useRef } from "react";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import ComponentCard from "components/Common/ComponentCard";
import Tabs from "components/Tabs";
import { EditIcon } from "components/Icons";
import CommonModal from "components/Common/CommonModal";
import CustomSelect from "components/Common/CustomSelect";
import tabComponentData from "./tabComponentData";
import useScrollManagement from "hooks/useScrollManagement";
import { tabCode } from "./tabCode";

const PreviewTabsComponent = ({ setSelectedTab }) => {
  const tabData = ["Default", "Pill"];

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
      >
        usage
      </span>
    </p>
  );
  const { tabsData, pilltabsData, previewtabsData } = tabComponentData();
  const [isModalVisible, setModalVisibility] = useState(false);
  const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
  const [buttonState, setButtonState] = useState(buttonStates[0]);
  const [activeSideTab, setActiveSideTab] = useState(0);
  const [tokenType, setTokenType] = useState("");
  const sectionRefs = useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  const showModal = () => {
    setModalVisibility(true);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div
          ref={sectionRefs?.current[0]}
          className="plt-content-wrapper dis-mb-24"
        >
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Default
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {tabsData.map((tabConfig, index) => (
          <div key={index} className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {tabConfig.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {tabConfig.desc}
              </p>
            </div>
            <ComponentCard
              editClick={showModal}
              showCode={true}
              htmlContent={tabCode(tabConfig?.dataItem[0], "someVariant")}
            >
              <Tabs dataItem={tabConfig.dataItem} variant="someVariant" />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div
          ref={sectionRefs?.current[1]}
          className="plt-content-wrapper dis-mb-24"
        >
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Pill
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {pilltabsData.map((tabConfig, index) => (
          <div key={index} className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {tabConfig.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {tabConfig.desc}
              </p>
            </div>
            <ComponentCard
              editClick={showModal}
              showCode={true}
              htmlContent={tabCode(tabConfig.dataItem[0], "pill")}
            >
              <Tabs dataItem={tabConfig.dataItem} variant="pill" />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
      </div>
      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                />
              </div>
            </div>
          </div>

          <div className="plt-preview plt-heading-xl dis-mb-32">
            {previewtabsData.map((tabConfig2, index) => (
              <Tabs
                key={index}
                dataItem={tabConfig2.dataItem}
                variant="someVariant"
              />
            ))}
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Border color
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                background.button.{buttonState.toLowerCase()}.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("bg");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Typography
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                uiText.lg.medium
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setTypoTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Text color default
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Text color active
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Padding
            </h6>
            <div className="plt-token-value-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">H:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">V:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>

              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal>

      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewTabsComponent;
