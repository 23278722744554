const formatDefaultTokens = (tokens, type) => {
  return tokens
    ? Object.entries(tokens).map(([key, value]) => ({
        key: `{color.${type}.${key}}`,
        value: value.value,
        varName: value.varName.replace("core-", ""),
        tokenName: value.tokenName.replace("dss-", "").split("-").join("."),
      }))
    : [];
};

/**
 *
 * @param {object} tokens
 * @returns {convertedTokens}
 */
const convertDefaultTokens = (tokens, theme) => {
  const types = [
    "primary",
    "secondary",
    "supportingColor1",
    "supportingColor2",
    "supportingColor3",
    "neutrals",
  ];

  const semanticType = ["negative", "caution", "positive", "informative"];

  // Normal types
  types?.map((type) => {
    tokens["converted" + type + "Tokens"] = formatDefaultTokens(
      tokens[`Theme/${theme}`]?.color[type],
      type
    );
  });

  // Semantic types
  semanticType?.map((semantic) => {
    tokens["converted" + semantic + "Tokens"] = formatDefaultTokens(
      tokens[`Theme/${theme}`]?.color.semantics[semantic],
      `semantics.${semantic}`
    );
  });

  return tokens;
};

/**
 *
 * @param {tokens} data
 * @param {Enum('primary','secondary','supporting-color-1')} type
 * @returns {String} color
 */
const getBaseColor = (data, type, theme) => {
  return data["converted" + type + "Tokens"]?.find(
    (p) => p.key.replace("core.", `Theme/${theme}.`) == `{${type}.600}`
  )?.value;
};

//Update DOM style element
const updateCSSVariables = (varName, value) => {
  document.documentElement.style.setProperty(varName, value);
};

//Update DOM style element
const updateCSSVariablesAll = (obj) => {
  Object.entries(obj).map(([varName, value]) => {
    document.documentElement.style.setProperty(varName, value);
  });
};

//Update DOM style element
const updateCSSVariablesAllObj = (obj) => {
  Object.entries(obj).map(([varName, value]) => {
    const cssVarName = `${varName}`;
    const cssVarValue = getComputedStyle(document.documentElement)
      .getPropertyValue(cssVarName)
      .trim();
    document.documentElement.style.setProperty(varName, cssVarValue);
  });
};

const getValueByPath = (obj, path) => {
  if (typeof path !== "string") {
    return null;
  }
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const filterTypeFromTokens = (data, filter) =>
  data &&
  Object.values(data)?.filter((d) => {
    return d.key.includes(filter);
  });

const removeTypeFromTokens = (data, filter) =>
  data &&
  Object.values(data)?.filter((d) => {
    return !d.tokenName.includes(filter);
  });
//filter
const filterTokenByType = (data, filter) =>
  data &&
  Object.values(data)?.filter((d) => {
    return d?.type == filter;
  });

const destructureTokens = (data) => {
  return Object.entries(data).map(
    ([key, { value, type, varName, tokenName, className }]) => {
      let destructuredColors = {};
      if (type === "color") {
        return (destructuredColors = {
          ...destructuredColors,
          ...{ value, type, varName, tokenName, className },
        });
      }
    }
  );
};

const replaceCharfromKey = (variable) => {
  return variable && variable.replace(/{|}/g, "");
};

const updateValueByPath = (obj, path, value) => {
  const keys = path?.split(".");
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    current = current[keys[i]] = current[keys[i]] || {};
  }

  if (current[keys[keys.length - 1]]) {
    current[keys[keys.length - 1]].value = value;
  } else {
    current[keys[keys.length - 1]] = { value: value };
  }
};

const constructBoxShadow = (shadow) => {
  if (!shadow) return "";

  const { x, y, blur, spread, color } = shadow;
  return `${x} ${y} ${blur} ${spread} ${color}`;
};

const getThemes = (tokens) => {
  let themes = [];
  Object.entries(tokens).forEach(([key, value]) => {
    if (key.startsWith("Theme/")) {
      themes.push(key.split("/")[1]);
    }
  });
  return themes;
};

const replaceTokenValue = (tokens, values) => {
  if (!values) return;

  const isToken = values.includes("{");
  if (!isToken) return values;

  const valueArray = values.split(" ");
  let result = "";
  valueArray.map((value) => {
    const replacedValue = value.replace("{", "").replace("}", "");
    let tokenObj = getValueByPath(tokens, "core." + replacedValue);
    result = result + " " + tokenObj?.value;
  });
  return result;
};

export {
  constructBoxShadow,
  convertDefaultTokens,
  destructureTokens,
  filterTokenByType,
  filterTypeFromTokens,
  getBaseColor,
  getValueByPath,
  removeTypeFromTokens,
  replaceCharfromKey,
  updateCSSVariables,
  updateCSSVariablesAll,
  updateCSSVariablesAllObj,
  updateValueByPath,
  getThemes,
  replaceTokenValue,
};
