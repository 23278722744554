import { validationIcon, trimmedCode } from "helpers/code";
import { tagCode } from "components/DesignSystemPage/TagComponent/PreviewTagComponent/tagCode";

export const textareaCode = (data) => {
  return trimmedCode(`<div class="dss-textarea-wrapper dss-textarea ${
    data.disabled ? " disabled " : " "
  } ${data.readOnlyState ? "read-only " : " "} ${data.fluid ? "dss-textarea-fluid " : " "}">
    <div class="dss-textarea-label-wrapper">
        <label for="textarea" class="dss-textarea-label">Label</label>
        <div class="dss-textarea-container">
            <div class="textarea-tag-container">
            ${
              data.tag
                ? tagCode(
                    {
                      prefixIcon: true,
                      statusIcon: false,
                      mode: "closable",
                      varient: "labelCloseicon",
                    },
                    "",
                    "small"
                  )
                : ""
            }
                <textarea placeholder="value" id="0" class="dss-textarea-field" spellcheck="false"></textarea>
            </div>
        </div>
    </div>
    <div class="dss-count-container">
        <h6 class="dss-count-text">Helper text</h6>
        <h6 class="dss-count-text">0/20</h6>
    </div>
    ${
      data.validation
        ? `
    <div class="dss-error-container">
        <span class="dss-error-icon">${validationIcon}</span>
        <h6 class="dss-error-message">Helpful validation message</h6>
    </div>`
        : ""
    }
</div>`);
};
