import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";

import { getComponentsSideData } from "assets/data/sideTabData";
import { textareaTokenTabData } from "assets/data/tokenTabData";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import PreviewTextareaComponent from "components/DesignSystemPage/TextareaComponent/PreviewTextareaComponent";
import UsageTextareaComponent from "components/DesignSystemPage/TextareaComponent/UsageTextareaComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";

import "assets/scss/pages/_DesignSystemComponents.scss";

const TextareaComponents = () => {
  const { id } = useParams();
  const { designSystemMeta, designSystem } = useGlobalContext();

  const componentName = "textarea";
  const componentKey = `Component/${componentName}`;
  const componentData = designSystem[componentKey];
  const tokenTableData = textareaTokenTabData(componentData, componentName);

  const tabData = [
    "Default",
    "Fluid",
    // "Supporting colors",
    // "Neutral colors",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const bannerData = {
    heading: "Textarea",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewTextareaComponent
            tabData={tabData}
            activeTab={activeTab}
            setSelectedTab={setSelectedTab}
            handleTabClick={handleTabClick}
          />
        );
      case 1:
        return (
          <div>
            <UsageTextareaComponent />
          </div>
        );
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title="Components"
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default TextareaComponents;
