import { trimmedCode } from "helpers/code";

export const modalHeaderCode = trimmedCode(`<div class="dss-modal-content">
    <div class="dss-modal-header" style={{borderBottom: "0px"}}>
        <div class="dss-modal-header-leading">
            <span class="dss-modal-header-icon prefix-icon"><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="var(--dss-color-icon-modal-primary)"
          >
            <rect
              x="4.75"
              y="4.75"
              width="14.3581"
              height="14.3581"
              stroke="var(--dss-color-icon-modal-primary)"
              stroke-width="1.5"
            ></rect>
            <path
              d="M5.5 5.5L18.3615 18.3615"
              stroke="var(--dss-color-icon-modal-primary)"
              stroke-width="1.5"
            ></path>
            <path
              d="M18.3563 5.5L5.5 18.3563"
              stroke="var(--dss-color-icon-modal-primary)"
              stroke-width="1.5"
            ></path></svg></span>
            <h5 class="dss-modal-header-title dss-font-color-primary">
          Title text
        </h5>
        </div>
        <span class="dss-modal-close-icon dss-iconbtn"><svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3298 2.67285L2.67725 13.3219"
            stroke="#171717"
            stroke-width="1.5"
            stroke-linecap="round"></path>
          <path
            d="M13.3332 13.3337L2.6665 2.66699"
            stroke="#171717"
            stroke-width="1.5"
            stroke-linecap="round"></path></svg></span>
    </div>
</div>`);

export const modalBodyCode = trimmedCode(`<div class="dss-modal-body">
  <div class="dss-modal-body-content">
    <div class="dss-preview dss-font-color-primary">modal-body</div>
  </div>
</div>`);

export const modalFooterCode = trimmedCode(`<div class="dss-modal-footer" style={{borderTop:"10px", width: "100%"}}>
  <button class="dss-btn dss-btn-secondary dss-btn-md" type="button">
    Cancel
  </button>
  <button class="dss-btn dss-btn-primary dss-btn-md" type="button">
    Apply
  </button>
</div>`);
