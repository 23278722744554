import React, { useState } from "react";

import { useGlobalContext } from "context/GlobalContext";

import { EditIcon } from "components/Icons";
import { addToLocalStorage } from "hooks/storageUtils";
import useScrollManagement from "hooks/useScrollManagement";
import colorSectionData from "pages/styles/colorSectionData";
import CommonModal from "components/Common/CommonModal";
import ComponentCard from "components/Common/ComponentCard";
import ColorPickerComponent from "components/DesignSystemPage/ColorPickerComponent";
import TabNavigation from "components/DesignSystemPage/TabNavigation";
import AlertDisplay from "components/Common/AlertComponent";

import "./PreviewAlertComponent.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { alertCode } from "./alertCode";

const PreviewAlertComponents = ({ tabData, setSelectedTab }) => {
  const ColorSectionComponent = ({
    title,
    description,
    colorData,
    type,
    setTokenState,
    buttonState,
    tokenState,
    initialColor,
  }) => {
    return (
      <div className="plt-content-wrapper dis-mb-32">
        {title && type?.length && (
          <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary font-weight-600 dis-mb-12">
            {title}
          </h6>
        )}
        {description && !type?.length && (
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24">
            {description}
          </p>
        )}

        {colorData.map((color, index) => (
          <>
            {color?.subTitle && (
              <p className="plt-subtitle plt-heading-xs plt-font-color-primary dis-mb-8">
                {color?.subTitle}
              </p>
            )}
            <ColorPickerComponent
              key={index}
              initialColor={initialColor}
              // buttonState={buttonState}
              setTokenState={setTokenState}
              type={type}
              tokenState={tokenState}
              baseColor={color.baseColor}
              id={color.id}
              shades={color.shades}
              tokenType={color.tokenType}
            />
          </>
        ))}
      </div>
    );
  };
  const [showAlertConfig, setShowAlertConfig] = useState(null);
  const [selectedTokenData, setSelectedTokenData] = useState({});
  const [tokenType, setTokenType] = useState("");
  const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);
  const colorSections = colorSectionData();
  const [tokenState, setTokenState] = useState({ key: "", value: "" });

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedAlertData, setSelectedAlert] = useState(false);
  const { designSystem } = useGlobalContext();

  const componentName = "alert";
  const componentKey = `Component/${componentName}`;
  const componentData = designSystem[componentKey];

  const getPropertyData = (property, childProperty) => {
    const data =
      property && property === "color"
        ? componentData[property][childProperty][componentName]
        : componentData[property][componentName];
    return data;
  };

  const handleButtonClick = (config) => {
    setShowAlertConfig(config);
    setTimeout(() => {
      setShowAlertConfig(null);
    }, 5000);
  };

  const onApplyChanges = async () => {
    addToLocalStorage([tokenState]);
    setColorTokenModalOpen(false);
  };

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  const commonDesc =
    "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container.";

  const alertConfigurations = [
    {
      title: "Informative",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-informative",
    },
    {
      title: "Caution",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-caution",
    },
    {
      title: "Success",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-success",
    },
    {
      title: "Danger",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-danger",
    },
  ];

  const showModal = (selectedAlert) => {
    setModalVisibility(true);
    setSelectedAlert(selectedAlert);
  };

  const [activeSideTab, setActiveSideTab] = useState(0);
  const sectionRefs = alertConfigurations?.map(() => React.useRef(null));

  const scrollToSection = useScrollManagement(
    sectionRefs,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        {alertConfigurations.map((config, index) => (
          <div
            key={config.title}
            ref={sectionRefs[index]}
            className="plt-content-wrapper"
          >
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {config.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {commonDesc}
              </p>
            </div>
            <ComponentCard
              editClick={() => showModal(config)}
              edit={true}
              showCode={true}
              htmlContent={alertCode(config)}
            >
              <AlertDisplay
                prefix={config?.prefix}
                closable={config?.closable}
                action={config?.action}
                actionFooter={config?.actionFooter}
                alerttype={config.alerttype}
                title={config.title}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}

        {showAlertConfig && (
          <AlertDisplay
            prefix={showAlertConfig.prefix}
            closable={showAlertConfig.closable}
            action={showAlertConfig.action}
            actionFooter={showAlertConfig.actionFooter}
            onClose={() => setShowAlertConfig(null)}
          />
        )}
      </div>

      {isModalVisible && selectedAlertData && (
        <CommonModal
          isOpen={isModalVisible}
          onRequestClose={() => {
            setModalVisibility(false);
          }}
          title="Tokens"
          onApply={() => setModalVisibility(false)}
          onCancelText="Cancel"
          onApplyText="Apply"
          variant="modal-tertiary"
        >
          <div className="plt-modal-body-content">
            <div className="plt-dropdown-secondary">
              <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
                <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common"></div>
              </div>
            </div>
            <div className="plt-preview dis-mb-32 d-flex justify-content-center">
              <AlertDisplay
                prefix={selectedAlertData?.prefix}
                closable={selectedAlertData?.closable}
                action={selectedAlertData?.action}
                actionFooter={selectedAlertData?.actionFooter}
                alerttype={selectedAlertData.alerttype}
                title={selectedAlertData.title}
                description="sample test..."
              />
            </div>
            <>
              <div className="plt-token-details dis-mb-32">
                <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                  Background color
                </h6>
                <div className="plt-token-value-item d-flex">
                  <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                    {
                      getPropertyData("color", "background")[
                        selectedAlertData?.title?.toLowerCase()
                      ]?.tokenName
                    }
                  </p>
                  <span
                    className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                    onClick={() => {
                      setSelectedTokenData(
                        getPropertyData("color", "background")[
                          selectedAlertData?.title?.toLowerCase()
                        ]
                      );
                      setTokenType("inputBg");
                      setColorTokenModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </span>
                </div>
              </div>
              <div className="plt-token-details dis-mb-32">
                <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                  Text color
                </h6>
                <div className="plt-token-value-item d-flex">
                  <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                    {
                      getPropertyData("color", "text")[
                        selectedAlertData?.title?.toLowerCase()
                      ]?.tokenName
                    }
                  </p>
                  <span
                    className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                    onClick={() => {
                      setSelectedTokenData(
                        getPropertyData("color", "text")[
                          selectedAlertData?.title?.toLowerCase()
                        ]
                      );
                      setTokenType("inputBg");
                      setColorTokenModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </span>
                </div>
              </div>
            </>
          </div>
        </CommonModal>
      )}

      {/* color modal */}
      {/* {isColorTokenModalOpen && (
				<ColorTokenModal
					isColorTokenModalOpen={isColorTokenModalOpen}
					setColorTokenModalOpen={setColorTokenModalOpen}
					setButtonSelectedColor={setButtonSelectedColor}
					buttonState={buttonState}
					selectedClassName={componentInfo}
					componentInfo={componentInfo}
					colorSections={colorSections}
					onApplyChanges={onApplyChanges}
					tokenType={tokenType}
					selectedTokenData={selectedTokenData}
					setTokenState={setTokenState}
				/>
			)} */}
      <CommonModal
        isOpen={isColorTokenModalOpen}
        onRequestClose={() => {
          setColorTokenModalOpen(false);
        }}
        title="Change token"
        onApply={onApplyChanges}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="change-token-modal"
      >
        <>
          <div className="plt-preview dis-mb-24">
            <AlertDisplay
              prefix={selectedAlertData?.prefix}
              closable={selectedAlertData?.closable}
              action={selectedAlertData?.action}
              actionFooter={selectedAlertData?.actionFooter}
              alerttype={selectedAlertData.alerttype}
              title={selectedAlertData.title}
              description="sample test..."
            />
          </div>
          <div className="plt-modal-body-content dis-mt-32 dis-pl-24 dis-pr-24">
            {selectedTokenData.value}
            {colorSections
              .filter((section) => section.title === "Semantic color")
              .map((section, index) => (
                <div key={index}>
                  <ColorSectionComponent
                    {...section}
                    onApplyChanges={onApplyChanges}
                    type={tokenType}
                    tokenState={selectedTokenData}
                    setTokenState={setTokenState}
                  />
                </div>
              ))}
          </div>
        </>
      </CommonModal>

      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewAlertComponents;
