import React, { useState } from "react";

import { useGlobalContext } from "context/GlobalContext";

import { ChromePicker } from "react-color";
import { addToLocalStorage } from "hooks/storageUtils";
import { generateColorShades } from "helpers/color";
import useColorConversion from "hooks/useColorConversion";

import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import { ModalCloseIcon } from "components/Icons";
import ColorSwatch from "components/DesignSystemPage/ColorSwatch/ColorSwatch";
import CheckboxComponent from "components/Common/CheckboxComponent";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "./ColorPickerComponent.scss";

const ColorPickerModal = ({
  isOpen,
  onClose,
  color,
  setColorModalOpen,
  handleColorChange,
  handleShadeChange,
  setColor,
  index,
}) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [colorOption, setColorOption] = useState("shadeRange");
  const [isChecked, setIsChecked] = useState(true);
  const { theme } = useGlobalContext();
  const handleColorOptionChange = (event) => {
    setColorOption(event.target.value);
    if (event.target.value !== "shadeRange") {
      setIsChecked(false);
    }
  };
  const handleApplyClick = () => {
    if (colorOption === "shadeRange") {
      handleColorChange(color, isChecked);
    } else if (colorOption === "editShade") {
      handleShadeChange(color, index);
    }
    setColorModalOpen(false);
    setIsColorPickerOpen(false);
  };

  if (!isOpen) {
    return null;
  }
  const handleCheckboxChange = (e) => {
    setIsChecked(e);
  };
  return (
    <div className="plt-modal-overlay">
      <div className="plt-modal-content">
        <div className="plt-modal-header">
          <h5 className="plt-modal-header-title plt-heading-sm plt-font-color-primary">
            Edit base color
          </h5>
          <span className="plt-modal-close-icon plt-iconbtn" onClick={onClose}>
            <ModalCloseIcon />
          </span>
        </div>
        <div className="plt-modal-body dis-pl-24 dis-pr-24">
          <ChromePicker
            color={color}
            onChangeComplete={(colorResult) => setColor(colorResult.hex)}
          />
        </div>
        <div className="plt-modal-footer">
          <PrimaryButton
            onClick={() => setIsColorPickerOpen(true)}
            text={"Apply"}
            // className={`dss-btn dss-btn-primary dss-ui-text-md-medium `}
            variant="primary"
            size="md"
          />
        </div>
      </div>
      <CommonModal
        isOpen={isColorPickerOpen}
        onRequestClose={() => setIsColorPickerOpen(false)}
        title="Apply?"
        onApply={handleApplyClick}
        onCancelText="Cancel"
        onApplyText="Apply"
        // variant="modal-secondary"
      >
        <div className="plt-modal-body-content">
          <div className="dss-custom-radio-group dss-custom-radio-group-vertical dis-pr-24 dis-pl-24 dis-pt-12 dis-pb-12">
            <label
              className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
                colorOption === "shadeRange" ? "checked" : ""
              }`}
            >
              <input
                className="dss-radio-input"
                type="radio"
                id="shadeRange"
                name="colorOption"
                value="shadeRange"
                checked={colorOption === "shadeRange"}
                onChange={handleColorOptionChange}
              />
              <span
                className="dss-radio-label dss-body-compact-md"
                htmlFor="shadeRange"
              >
                Change resulting shade range
              </span>
            </label>
            <label
              className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
                colorOption === "editShade" ? "checked" : ""
              }`}
            >
              <input
                className="dss-radio-input"
                type="radio"
                id="editShade"
                name="colorOption"
                value="editShade"
                checked={colorOption === "editShade"}
                onChange={handleColorOptionChange}
              />
              <span
                className="dss-radio-label dss-body-compact-md"
                htmlFor="editShade"
              >
                Edit only this shade
              </span>
            </label>
            {colorOption === "shadeRange" && (
              <CheckboxComponent
                label={`Apply ${theme === "light" ? "dark" : "light"} mode color?`}
                size="small"
                variant="default"
                disabled={false}
                value={isChecked}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            )}
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

const AlphaPickerModal = ({
  isOpen,
  onClose,
  setColorModalOpen,
  onApply,
  index,
  setColor,
  color,
}) => {
  const handleApplyClick = () => {
    onApply(color, index);
    setColorModalOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="plt-modal-overlay">
      <div className="plt-modal-content shades">
        <div className="plt-modal-header">
          <h5 className="plt-modal-header-title plt-heading-sm plt-font-color-primary">
            Edit shade
          </h5>
          <span
            className="cursor-pointer plt-iconbtn plt-iconbtn-sm"
            onClick={onClose}
          >
            <ModalCloseIcon />
          </span>
        </div>
        <div className="plt-modal-body dis-pl-24 dis-pr-24">
          <ChromePicker
            color={color}
            onChangeComplete={(colorResult) => setColor(colorResult.hex)}
          />
        </div>

        <div className="plt-modal-footer">
          <PrimaryButton
            onClick={() => handleApplyClick(true)}
            text={"Apply"}
            // className={`plt-btn plt-btn-primary`}
            variant="primary"
            size="md"
          />
        </div>
      </div>
    </div>
  );
};

const ColorPickerComponent = (props) => {
  const { selectedButtonColor, setButtonSelectedColor, theme } =
    useGlobalContext();

  const {
    baseColor,
    shades,
    tokenType,
    type,
    setTokenState,
    buttonState,
    tokenState,
    initialColor,
    disbleColorPickerModal = false,
  } = props;

  const [color, setColor] = useState(baseColor);
  const [colorShades, setColorShades] = useState(shades);

  React.useEffect(() => {
    setColor(baseColor);
  }, [baseColor]);

  React.useEffect(() => {
    setColorShades(shades);
    setColorShade(shades);
  }, [shades]);

  React.useEffect(() => {
    shades?.length ?? setColorShade(shades);
  }, [shades]);

  const [colorShade, setColorShade] = useState(shades);

  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [colorAlphaModalOpen, setColorAlphaModalOpen] = useState(false);
  const { rgbaToHex } = useColorConversion();
  const [activeIndex, setActiveIndex] = useState(type?.length ? null : 5);
  const [activeShadeIndex, setActiveShadeIndex] = useState(null);

  const openColorPicker = (index) => {
    setActiveShadeIndex(index);
    setColorModalOpen(true);
  };

  // Change all colors
  const handleColorChange = async (newColor, isChecked) => {
    setColor(newColor);
    const newShades = generateColorShades(newColor, tokenType, theme);
    setColorShades(newShades);
    updateCSSVariables(newShades);

    const newShadesFiltered = newShades.map(({ key, value }) => {
      return {
        key: key.replace("core.", `Theme/${theme}.`),
        value,
      };
    });
    addToLocalStorage(newShadesFiltered);
    //Generate other shades when update other shade is checked
    if (isChecked) {
      const otherTheme = theme === "light" ? "dark" : "light";
      const newOtherShades = generateColorShades(
        newColor,
        tokenType,
        otherTheme
      );
      const newOtherhadesFiltered = newOtherShades.map(({ key, value }) => {
        return {
          key: key.replace("core.", `Theme/${otherTheme}.`),
          value,
        };
      });
      addToLocalStorage(newOtherhadesFiltered);
    }
  };

  const updateCSSVariables = (shades) => {
    shades.forEach((shade) => {
      document.documentElement.style.setProperty(shade.varName, shade.value);
    });
  };

  // Change individual color
  const handleShadeChange = async (newColor, index) => {
    if (!colorShades[index]) {
      console.error(`Shade at index ${index} is undefined`);
      return;
    }

    const isRgba = /^rgba/.test(newColor);
    const convertedColor = isRgba ? rgbaToHex(newColor) : newColor;

    const updatedShades = colorShades.map((shade, idx) => {
      if (idx === index) {
        return { ...shade, value: convertedColor };
      }
      return shade;
    });
    // *
    try {
      document.documentElement.style.setProperty(
        updatedShades[index].varName,
        convertedColor
      );

      const tokenData = [
        {
          key: `Theme/${theme}.${updatedShades[index].tokenName}`,
          value: convertedColor,
        },
      ];
      addToLocalStorage(tokenData);
      setColorShades(updatedShades);
    } catch (error) {
      console.error("Failed to update token:", error);
    }
  };

  const openAlphaPickerForShade = (index) => {
    setActiveShadeIndex(index);
    setColorAlphaModalOpen(true);
  };

  const handleColorClick = (shade, index) => {
    setButtonSelectedColor(shade, index);
    const value = getComputedStyle(document.documentElement).getPropertyValue(
      shade.varName
    );
    const shadeKeyValue = shade.key;

    // setActiveIndex(index);
    const applyChanges = (cssVarName, tokenKey) => {
      document.documentElement.style.setProperty(cssVarName, value);

      setTokenState({
        key: tokenKey,
        value:
          type === "bg" ||
          type === "inputBg" ||
          type === "text" ||
          type === "border"
            ? shadeKeyValue
            : value,
      });
    };

    if (type === "inputBg") {
      applyChanges(tokenState.varName, tokenState.key);
    }

    if (type === "bg") {
      applyChanges(
        tokenState[buttonState?.value?.toLowerCase()]?.bg.varName,
        tokenState[buttonState?.value.toLowerCase()]?.bg.key
      );
    }

    if (type === "border") {
      applyChanges(
        tokenState[buttonState?.value?.toLowerCase()]?.border.varName,
        tokenState[buttonState?.value?.toLowerCase()]?.border.key
      );
    } else if (type === "text") {
      applyChanges(
        tokenState[buttonState?.value?.toLowerCase()]?.text.varName,
        tokenState[buttonState?.value?.toLowerCase()]?.text.key
      );
    } else if (type === "elevation") {
      applyChanges(shade.varName, shade.key);
    } else {
      if (index === 5 && !disbleColorPickerModal) {
        setColor(value);
        openColorPicker(index);
      } else if (!disbleColorPickerModal) {
        setColorShade(value);
        openAlphaPickerForShade(index);
      }
    }
  };

  return (
    <div className="plt-color-picker-container dis-mb-16">
      <div className="plt-color-picker-modal">
        <ColorPickerModal
          setColor={setColor}
          index={activeShadeIndex}
          isOpen={colorModalOpen}
          setColorModalOpen={setColorModalOpen}
          onClose={() => setColorModalOpen(false)}
          color={color}
          handleColorChange={handleColorChange}
          handleShadeChange={handleShadeChange}
        />
        <AlphaPickerModal
          index={activeShadeIndex}
          setColor={setColorShade}
          isOpen={colorAlphaModalOpen}
          setColorModalOpen={setColorAlphaModalOpen}
          onClose={() => setColorAlphaModalOpen(false)}
          color={colorShade}
          onApply={handleShadeChange}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="plt-theme-pallette-lists d-flex">
          {colorShades?.map((shade, index) => (
            <ColorSwatch
              type={type}
              initialColor={initialColor}
              key={index}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue(shade.varName)}
              varName={shade.varName}
              tokenType={tokenType}
              selectedButtonColor={selectedButtonColor}
              shade={shade}
              onClick={() => handleColorClick(shade, index)}
              index={index}
              activeIndex={activeIndex}
              disableEdit={disbleColorPickerModal}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColorPickerComponent;
