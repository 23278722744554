import React from "react";

import PrimaryButton from "components/Common/PrimaryButton";

import "./ComponentButtonCard.scss";

const ComponentButtonCard = ({ state, buttonType }) => {
  return (
    <div className="plt-component-card">
      <div className="plt-component-btn-wrapper d-flex align-items-center justify-content-center">
        <PrimaryButton
          text={"Button text"}
          variant={buttonType}
          className={`${state ? state : ""} pointer-none`}
          size="lg"
        />
      </div>
    </div>
  );
};

export default ComponentButtonCard;
