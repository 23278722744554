import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./CommonModal.scss";
import { ModalCloseIcon } from "../../Icons/index";
import PrimaryButton from "../../Common/PrimaryButton";

const CommonModal = ({
	isOpen,
	children,
	onRequestClose,
	title,
	onApply,
	variant,
	onCancelText = "Cancel",
	onApplyText = "Apply",
	bodyClassName,
	classPrefix = "dss",
}) => {
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Enter" && isOpen && onApply) {
				event.preventDefault(); // Prevent form submission or other default actions
				onApply();
			}
		};

		if (isOpen) {
			document.body.style.overflow = "hidden";
			window.addEventListener("keydown", handleKeyDown);
		}

		return () => {
			document.body.style.overflow = "unset";
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [isOpen, onApply]);

	if (!isOpen) return null;

	return ReactDOM.createPortal(
		<div className={`plt-modal-overlay ${variant} ${classPrefix}`}>
			<div className="plt-modal-content" onClick={(e) => e.stopPropagation()}>
				<div className="plt-modal-header">
					<h5 className="plt-modal-header-title plt-heading-sm plt-font-color-primary">{title}</h5>
					<span className="plt-modal-close-icon plt-iconbtn" onClick={onRequestClose}>
						<ModalCloseIcon />
					</span>
				</div>
				<div className={`plt-modal-body ${bodyClassName}`}>{children}</div>
				<div className="plt-modal-footer">
					<PrimaryButton
						buttonType={classPrefix}
						variant="secondary"
						size="md"
						text={onCancelText}
						onClick={onRequestClose}
					/>
					{onApply && (
						<PrimaryButton buttonType={classPrefix} variant="primary" size="md" text={onApplyText} onClick={onApply} />
					)}
				</div>
			</div>
		</div>,
		document.body,
	);
};

export default CommonModal;
