import React, { useState } from "react";

import { useGlobalContext } from "context/GlobalContext";
import { useParams } from "react-router-dom";

import { getComponentsSideData } from "assets/data/sideTabData";
import { checkboxTokenTabData } from "assets/data/tokenTabData";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import PreviewCheckboxComponent from "components/DesignSystemPage/CheckboxComponent/PreviewCheckboxComponent";
import UsageCheckboxComponent from "components/DesignSystemPage/CheckboxComponent/UsageCheckboxComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";

import "assets/scss/pages/_DesignSystemComponents.scss";

const CheckboxComponents = () => {
  const { id } = useParams();
  const { designSystemMeta, designSystem } = useGlobalContext();

  const componentName = "checkbox";
  const componentKey = `Component/${componentName}`;
  const componentData = designSystem[componentKey];
  const tokenTableData = checkboxTokenTabData(componentData, componentName);

  const tabData = [
    "Default",
    "Outline",
    // "Disabled",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const bannerData = {
    heading: "Check box",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewCheckboxComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      case 1:
        return <UsageCheckboxComponent />;
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title="Components"
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default CheckboxComponents;
