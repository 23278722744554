import { trimmedCode } from "helpers/code";

export const toasterCode = (data) => {
  return trimmedCode(`<div class="dss-toaster">
    <div class="dss-toaster-leading">
        ${
          data.prefix
            ? `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.3335 9.58333L8.05705 14.1667L17.5002 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg> `
            : ""
        }
        <div class="dss-toaster-msg-container ${data.actionFooter ? " dss-toaster-action-footer " : " "}">
            <h6 class="dss-toaster-msg">Message</h6>
            <div class="dss-toaster-trailing">
                ${
                  data.action
                    ? `
                <button class="dss-btn dss-btn-link dss-btn-xs" type="button">Action</button>`
                    : ""
                }
            </div>
        </div>
    </div>
    ${
      data.closable
        ? `<span class="dss-toaster-close-icon"><svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3298 2.67334L2.67725 13.3224"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>
      <path
        d="M13.3332 13.3332L2.6665 2.6665"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      ></path></svg
  ></span>`
        : ""
    }
</div>`);
};
