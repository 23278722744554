import React from "react";

import { useGlobalContext } from "context/GlobalContext";
import { toast } from "react-toastify";

import { ColorCopyIcon, ColorEditIcon } from "components/Icons";
import Skeleton from "react-loading-skeleton";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";

const ColorSwatch = ({
  color,
  onClick,
  index,
  activeIndex,
  initialColor,
  varName,
  type,
  tokenType,
  shade,
  selectedButtonColor,
  disableEdit,
}) => {
  const { designSystemMeta } = useGlobalContext();
  const hexColor = color?.toUpperCase();

  const rgb = hexColor
    ? {
        r: parseInt(hexColor.slice(1, 3), 16),
        g: parseInt(hexColor.slice(3, 5), 16),
        b: parseInt(hexColor.slice(5, 7), 16),
      }
    : null;

  const rgbColor = rgb ? `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})` : null;

  const brightness = rgb
    ? Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
    : null;

  const colorActive =
    (type?.length && selectedButtonColor?.key === shade?.key) ||
    (initialColor === shade.key && !selectedButtonColor?.key);

  const textColorClass =
    brightness > 125
      ? "plt-theme-pallette-item-dark"
      : "plt-theme-pallette-item-light";

  const className = `plt-theme-pallette-item ${
    activeIndex === index ||
    colorActive ||
    (initialColor === shade.key && !selectedButtonColor)
      ? "active"
      : ""
  } ${textColorClass}`.trim();

  const buttonClassName = `${className} button`;
  const baseColorStyle = {
    backgroundColor: `var(${varName})`,
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(hexColor);
    toast.success(`Copied ${hexColor} to clipboard!`);
  };

  const detailBlockClassName = `plt-color-detail-block ${activeIndex === index ? "active" : ""}`;

  const handleClick = !type?.length ? onClick : undefined;
  const colorClick = type?.length ? onClick : undefined;

  return (
    <>
      {hexColor && rgbColor && tokenType ? (
        <div
          className={`${type === "bg" || type === "text" || type === "border" ? buttonClassName : className} `}
          onClick={colorClick}
          style={baseColorStyle}
        >
          <div className={detailBlockClassName}>
            {hexColor && rgbColor && tokenType ? (
              <>
                <div className="plt-body-xs plt-theme-pallette-block">
                  <span className="plt-body-xs plt-theme-pallette-text">{`HEX ${hexColor}`}</span>
                </div>
                <div className="plt-body-xs d-flex align-items-center plt-theme-pallette-block">
                  <>
                    <span className="plt-body-xs plt-theme-pallette-text">
                      {rgbColor}
                    </span>
                    <div
                      onClick={handleCopyClick}
                      className="plt-theme-pallette-copy-icon dis-ml-4"
                    >
                      <ColorCopyIcon />
                    </div>
                  </>
                </div>

                <div className="plt-body-xs d-flex align-items-center plt-theme-pallette-block ">
                  <span className="plt-body-xs plt-theme-pallette-text">
                    {tokenType}-{`${(index + 1) * 100}`}
                  </span>
                  {!disableEdit && (
                    <AuthAction>
                      <Permission designSystem={designSystemMeta}>
                        <div
                          onClick={handleClick}
                          className="plt-theme-pallette-edit-icon dis-ml-8 cursor-pointer"
                        >
                          <ColorEditIcon />
                        </div>
                      </Permission>
                    </AuthAction>
                  )}
                </div>
              </>
            ) : (
              <Skeleton baseColor="#a6a6a663" width={100} height={110} />
            )}
          </div>
        </div>
      ) : (
        <Skeleton baseColor="#a6a6a663" width={85} height={125} />
      )}
    </>
  );
};

export default ColorSwatch;
