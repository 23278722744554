import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { getComponentsSideData } from "assets/data/sideTabData";
import "assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import PreviewTooltipComponent from "components/DesignSystemPage/TooltipComponent/PreviewTooltipComponent";
import UsageTooltipComponent from "components/DesignSystemPage/TooltipComponent/UsageTooltipComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";
import { tooltipTokenTabData } from "assets/data/tokenTabData";

const Tooltip = () => {
  const { id } = useParams();
  const { designSystemMeta, designSystem } = useGlobalContext();

  const componentName = "tooltip";
  const componentKey = `Component/${componentName}`;
  const componentData = designSystem[componentKey];
  const tokenTableData = tooltipTokenTabData(componentData, componentName);

  const tabData = [
    "Short",
    "Truncate",
    "Wrap",
    // "Neutral colors",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const bannerData = {
    heading: "Tooltip",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        // return <div>content1</div>;
        return (
          <PreviewTooltipComponent
            tabData={tabData}
            activeTab={activeTab}
            setSelectedTab={setSelectedTab}
            setActiveTab={setActiveTab}
          />
        );
      case 1:
        return (
          <div>
            <UsageTooltipComponent />
          </div>
        );
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title="Components"
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default Tooltip;
