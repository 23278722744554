import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";

import { getComponentsSideData } from "assets/data/sideTabData";
import { avatarTokenTabData } from "assets/data/tokenTabData";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import PreviewAvatarComponent from "components/DesignSystemPage/AvatarComponent/PreviewAvatarComponent";
import UsageAvatarComponent from "components/DesignSystemPage/AvatarComponent/UsageAvatarComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";

import "assets/scss/pages/_DesignSystemComponents.scss";

const AvatarComponents = () => {
  const { id } = useParams();

  const { designSystemMeta, designSystem } = useGlobalContext();

  const componentName = "avatar";
  const componentKey = `Component/${componentName}`;
  const componentData = designSystem[componentKey];
  const tokenTableData = avatarTokenTabData(componentData, componentName);

  const [selectedTab, setSelectedTab] = useState(0);

  const bannerData = {
    heading: "Avatar",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <PreviewAvatarComponent />;
      case 1:
        return <UsageAvatarComponent />;
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title="Components"
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default AvatarComponents;
