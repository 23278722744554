import { tagCode } from "components/DesignSystemPage/TagComponent/PreviewTagComponent/tagCode";
import { prefixSuffixIcon, arrowIcon, trimmedCode } from "helpers/code";

const prefix = (data) => {
  return `${
    data.prefix
      ? `<span class="prefix-icon">
            ${prefixSuffixIcon}</span>`
      : ""
  }`;
  //  ${
  //    data.tag
  //      ? tagCode(
  //          {
  //            prefixIcon: false,
  //            statusIcon: false,
  //            mode: "",
  //            varient: "labelCloseicon",
  //            disable: false,
  //            varient: "label",
  //            label: "15",
  //          },
  //          "",
  //          "normal"
  //        )
  //      : ""
  //  }`;
};

const suffix = (data, variant) => {
  return `
   ${
     variant === "pill"
       ? tagCode(
           {
             prefixIcon: false,
             statusIcon: false,
             mode: "",
             varient: "labelCloseicon",
             disable: false,
             label: "15",
           },
           "",
           "normal",
           "15"
         )
       : ""
   }
  ${
    data.suffix
      ? `<span class="suffix-icon">${data.arrow ? `${arrowIcon}` : ""}</span>`
      : ""
  }
 `;
};

export const tabCode = (data, variant) => {
  console.log(data);
  return trimmedCode(`<div class="dss-tab">
    <div class="dss-tab-header dss-tab-header-${variant}">
        <button class="dss-tab-btn active dss-tab-btn-${variant}">${prefix(data)}Tab title 1${suffix(data, variant)}</button>
        <button class="dss-tab-btn dss-tab-btn-${variant}">${prefix(data)}Tab title 2${suffix(data, variant)}</button>
        <button class="dss-tab-btn dss-tab-btn-${variant}">${prefix(data)}Tab title 3${suffix(data, variant)}</button>
    </div>
    <div class="dss-tab-body">
        <div class="dss-font-color-primary">content 1</div>
    </div>
</div>`);
};
