import { trimmedCode } from "helpers/code";

export const paginationCode = trimmedCode(`<div class="dss-pagination">
    <ul class="pagination d-flex">
        <li class="pagination-list pagination-list-icon pagination-list-disabled">
            <span class="pagination-list-item"><svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33331 10.6667L1.66665 6.00004L6.33331 1.33337"
            stroke="#6D6D6D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path></svg
      ></span>
        </li>
        <li class="pagination-list"><span class="pagination-list-item">1</span></li>
        <li class="pagination-list">
            <span class="pagination-list-item active">2</span>
        </li>
        <li class="pagination-list"><span class="pagination-list-item">3</span></li>
        <li class="pagination-list"><span class="pagination-list-item">4</span></li>
        <li class="pagination-list"><span class="pagination-list-item">5</span></li>
        <li class="pagination-list">
            <span class="pagination-list-item">...</span>
        </li>
        <li class="pagination-list">
            <span class="pagination-list-item">10</span>
        </li>
        <li class="pagination-list pagination-list-icon">
            <span class="pagination-list-item"><svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.66669 1.33329L6.33335 5.99996L1.66669 10.6666"
            stroke="#171717"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path></svg
      ></span>
        </li>
    </ul>
</div>`);
