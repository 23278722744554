import React, { useState } from "react";

import { useGlobalContext } from "context/GlobalContext";
import useScrollManagement from "hooks/useScrollManagement";

import checkboxDetail from "assets/images/png/checkboxDetail.png";
import CheckboxAlignment from "assets/images/png/CheckboxAlignment.png";
import CheckboxPlacement from "assets/images/png/CheckboxPlacement.png";
import CheckboxPlacementImg from "assets/images/png/CheckboxPlacementImg.png";
import CheckboxPlacementlabel from "assets/images/png/CheckboxPlacementlabel.png";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import GuidelineCardCheckbox from "components/Common/Guidelines/GuidelineCardCheckbox";
import DetailItem from "components/DetailItem";

const details = [
  {
    number: 1,
    title: "Checkbox input",
    description:
      " A checkbox input indicating the appropriate state. By default it is unselected.",
  },
  {
    number: 2,
    title: "Checkbox label",
    description: "Describes the information you want to select or unselect.",
  },
  {
    number: 3,
    title: "Checkbox Tick",
    description:
      "Indicates a selected option or confirms that an item or task has been completed",
  },
];

const UsageCheckboxComponent = () => {
  const { isLoading } = useGlobalContext();
  const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              The checkbox component is comprised of a checkbox label and a
              checkbox input. If there is a group of checkboxes, a group label
              can be added.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={checkboxDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map((detail) => (
              <DetailItem
                key={detail.number}
                number={detail.number}
                title={detail.title}
                description={detail.description}
              />
            ))}
          </ul>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[1]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Alignment
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              Checkbox labels are positioned to the right of their inputs. If
              there is a checkbox grouping, they can be laid out vertically or
              horizontally depending on the use case and the structure of the
              UI. When possible, arrange the checkbox and radio button groups
              vertically for easier reading.
            </p>
          </div>

          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center dis-p-0">
            <img src={CheckboxAlignment} alt="userimage" />
          </div>

          <div className="dis-mt-40 dis-mb-40">
            <div className="plt-content-wrapper dis-mb-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[2]}
              >
                Placement
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                The checkbox component is often used in forms. Forms can be
                placed on a full page, in a modal or in a side panel. A checkbox
                can also be used for agreeing to terms and conditions or to
                filter information.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={CheckboxPlacement} alt="userimage" />
            </div>

            <div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap  dis-mt-24">
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Checkboxes in a form should be placed at least 32px (layout-03)
                below or before the next component. Spacing of 24px (layout-02)
                or 16px (layout-01) can also be used when space is more
                restricted or if the form is more complex.
              </p>

              <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                <img src={CheckboxPlacementImg} alt="userimage" />
              </div>

              <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                <img src={CheckboxPlacementlabel} alt="userimage" />
              </div>
            </div>

            {/* <p
                className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}
              >
                {data.detail}
              </p>
              {data.detail_list} */}
          </div>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
              ref={sectionRefs.current[3]}
            >
              Guidelines
            </h6>
            <ul className="plt-detail-list dis-mt-12">
              <li className="plt-body-md plt-font-color-primary">
                We recommend checkbox labels being fewer than three words.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                If you are tight on space, consider rewording the label. Do not
                truncate checkbox label text with an ellipsis.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Long labels may wrap to a second line, and this is preferable to
                truncation.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Text should wrap beneath the checkbox so the control and label
                are top aligned.
              </li>
            </ul>
          </div>

          <GuidelineCardCheckbox />
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageCheckboxComponent;
