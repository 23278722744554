import React from "react";
import { Link } from "react-router-dom";
import { PrimaryLogo } from "../Icons/index";
import "./AuthLayout.scss";

const AuthLayout = ({ children }) => {
  return (
    <div className="container">
      <div className="left">
        <div className="logo">
          <Link to="/login" className="logo-link d-flex align-items-center">
            <span className="logo-icon">
              <PrimaryLogo />
            </span>
            {/* <h2 className="logo-text dis-ml-12">Dessign</h2> */}
          </Link>
        </div>
        <div className="children" style={{ height: "100%" }}>
          {children}
        </div>
      </div>
      <div className="right">
        <img src="https://i.ibb.co/yBgxSG3/s.png" alt="" />
      </div>
    </div>
  );
};

export default AuthLayout;
