import { useGlobalContext } from "context/GlobalContext";
import { useEffect, useState } from "react";

const useTokensData = () => {
  const { designSystem, theme, setTheme } = useGlobalContext();
  const [tokensData, setTokensData] = useState([]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedTheme = localStorage.getItem("theme");
      if (storedTheme) {
        setTheme(storedTheme);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (designSystem) {
      const themeKey = `Theme/${theme}`;
      const createTokenData = (
        bg,
        text,
        spacing,
        radius,
        typography,
        border,
        elevation,
        borderWidth,
        underline
      ) => ({
        bg: {
          className: bg?.className,
          key: bg?.key,
          tokenName: bg?.tokenName,
          type: bg?.type,
          value: bg?.value,
          varName: bg?.varName,
        },
        text: {
          className: text?.className,
          key: text?.key,
          tokenName: text?.tokenName,
          type: text?.type,
          value: text?.value,
          varName: text?.varName,
        },
        spacing: {
          normal: {
            className: spacing?.normal?.className,
            key: spacing?.normal?.key,
            tokenName: spacing?.normal?.tokenName,
            type: spacing?.normal?.type,
            value: spacing?.normal?.value,
            varName: spacing?.normal?.varName,
          },
          dense: {
            className: spacing?.dense?.className,
            key: spacing?.dense?.key,
            tokenName: spacing?.dense?.tokenName,
            type: spacing?.dense?.type,
            value: spacing?.dense?.value,
            varName: spacing?.dense?.varName,
          },
          large: {
            className: spacing?.large?.className,
            key: spacing?.large?.key,
            tokenName: spacing?.large?.tokenName,
            type: spacing?.large?.type,
            value: spacing?.large?.value,
            varName: spacing?.large?.varName,
          },
        },
        radius: {
          normal: {
            className: radius?.normal?.className,
            key: radius?.normal?.key,
            tokenName: radius?.normal?.tokenName,
            type: radius?.normal?.type,
            value: radius?.normal?.value,
            varName: radius?.normal?.varName,
          },
          dense: {
            className: radius?.dense?.className,
            key: radius?.dense?.key,
            tokenName: radius?.dense?.tokenName,
            type: radius?.dense?.type,
            value: radius?.dense?.value,
            varName: radius?.dense?.varName,
          },
          large: {
            className: radius?.large?.className,
            key: radius?.large?.key,
            tokenName: radius?.large?.tokenName,
            type: radius?.large?.type,
            value: radius?.large?.value,
            varName: radius?.large?.varName,
          },
        },
        typography: {
          normal: {
            className: typography?.normal?.className,
            key: typography?.normal?.key,
            tokenName: typography?.normal?.tokenName,
            type: typography?.normal?.type,
            value: typography?.normal?.value,
            varName: typography?.normal?.varName,
          },
          dense: {
            className: typography?.dense?.className,
            key: typography?.dense?.key,
            tokenName: typography?.dense?.tokenName,
            type: typography?.dense?.type,
            value: typography?.dense?.value,
            varName: typography?.dense?.varName,
          },
          large: {
            className: typography?.large?.className,
            key: typography?.large?.key,
            tokenName: typography?.large?.tokenName,
            type: typography?.large?.type,
            value: typography?.large?.value,
            varName: typography?.large?.varName,
          },
        },
        border: {
          className: border?.className,
          key: border?.key,
          tokenName: border?.tokenName,
          type: border?.type,
          value: border?.value,
          varName: border?.varName,
        },
        elevation: {
          normal: {
            className: elevation?.pressednormal?.className,
            key: elevation?.pressednormal?.key,
            tokenName: elevation?.pressednormal?.tokenName,
            type: elevation?.pressednormal?.type,
            value: elevation?.pressednormal?.value,
            varName: elevation?.pressednormal?.varName,
          },
          dense: {
            className: elevation?.presseddense?.className,
            key: elevation?.presseddense?.key,
            tokenName: elevation?.presseddense?.tokenName,
            type: elevation?.presseddense?.type,
            value: elevation?.presseddense?.value,
            varName: elevation?.presseddense?.varName,
          },
          large: {
            className: elevation?.pressedlarge?.className,
            key: elevation?.pressedlarge?.key,
            tokenName: elevation?.pressedlarge?.tokenName,
            type: elevation?.pressedlarge?.type,
            value: elevation?.pressedlarge?.value,
            varName: elevation?.pressedlarge?.varName,
          },
        },
        borderWidth: {
          normal: {
            className: borderWidth?.normal?.className,
            key: borderWidth?.normal?.key,
            tokenName: borderWidth?.normal?.tokenName,
            type: borderWidth?.normal?.type,
            value: borderWidth?.normal?.value,
            varName: borderWidth?.normal?.varName,
          },
          dense: {
            className: borderWidth?.dense?.className,
            key: borderWidth?.dense?.key,
            tokenName: borderWidth?.dense?.tokenName,
            type: borderWidth?.dense?.type,
            value: borderWidth?.dense?.value,
            varName: borderWidth?.dense?.varName,
          },
          large: {
            className: borderWidth?.large?.className,
            key: borderWidth?.large?.key,
            tokenName: borderWidth?.large?.tokenName,
            type: borderWidth?.large?.type,
            value: borderWidth?.large?.value,
            varName: borderWidth?.large?.varName,
          },
        },
        underline: {
          normal: {
            className: underline?.normal?.className,
            key: underline?.normal?.key,
            tokenName: underline?.normal?.tokenName,
            type: underline?.normal?.type,
            value: underline?.normal?.value,
            varName: underline?.normal?.varName,
          },
          dense: {
            className: underline?.dense?.className,
            key: underline?.dense?.key,
            tokenName: underline?.dense?.tokenName,
            type: underline?.dense?.type,
            value: underline?.dense?.value,
            varName: underline?.dense?.varName,
          },
          large: {
            className: underline?.large?.className,
            key: underline?.large?.key,
            tokenName: underline?.large?.tokenName,
            type: underline?.large?.type,
            value: underline?.large?.value,
            varName: underline?.large?.varName,
          },
        },
      });
      const data = [
        {
          primary: {
            default: createTokenData(
              designSystem["Component/button"]?.color?.background?.button?.fill
                ?.primary,
              designSystem["Component/button"]?.color?.text?.button?.fill
                ?.primary,
              designSystem["Component/button"]?.spacing?.button?.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.color?.border?.button?.fill
                ?.primary,
              designSystem["Component/button"]?.boxShadow?.button?.fill,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            hovered: createTokenData(
              designSystem["Component/button"]?.color?.background?.button?.fill
                ?.hovered,
              designSystem["Component/button"]?.color?.text?.button?.fill
                ?.primary,
              designSystem["Component/button"]?.spacing?.button?.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.color?.border?.button?.fill
                ?.hovered,
              designSystem["Component/button"]?.boxShadow?.button?.fill,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            pressed: createTokenData(
              designSystem["Component/button"]?.color?.background?.button?.fill
                ?.pressed,
              designSystem["Component/button"]?.color?.text?.button?.fill
                ?.primary,
              designSystem["Component/button"]?.spacing?.button?.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"].color?.border?.button?.fill
                ?.pressed,
              designSystem["Component/button"]?.boxShadow?.button.fill,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            disabled: createTokenData(
              designSystem["Component/button"]?.color?.background?.button?.fill
                ?.disabled,
              designSystem["Component/button"]?.color?.text?.button?.fill
                ?.disabled,
              designSystem["Component/button"]?.spacing?.button?.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"].color?.border?.button?.fill
                ?.disabled,
              designSystem["Component/button"]?.boxShadow?.button.fill,
              designSystem["Component/button"]?.borderWidth?.button
            ),
          },
        },
        {
          danger: {
            default: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.danger
                ?.primary,
              designSystem["Component/button"]?.color?.text?.button?.danger
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.color?.border?.button?.danger
                ?.primary,
              designSystem["Component/button"]?.boxShadow?.button?.danger,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            hovered: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.danger
                ?.hovered,
              designSystem["Component/button"]?.color?.text?.button?.danger
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.color?.border?.button?.danger
                ?.hovered,
              designSystem["Component/button"]?.boxShadow?.button?.danger,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            pressed: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.danger
                ?.pressed,
              designSystem["Component/button"]?.color?.text?.button?.danger
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.color?.border?.button?.danger
                ?.pressed,
              designSystem["Component/button"]?.boxShadow?.button?.danger,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            disabled: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.danger
                ?.disabled,
              designSystem["Component/button"]?.color?.text?.button?.danger
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.color?.border?.button?.danger
                ?.disabled,
              designSystem["Component/button"]?.boxShadow?.button?.danger,
              designSystem["Component/button"]?.borderWidth?.button
            ),
          },
        },
        {
          default: {
            default: createTokenData(
              designSystem["Component/button"]?.color.background?.button
                ?.default?.primary,
              designSystem["Component/button"]?.color?.text?.button?.default
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.default
                ?.primary,
              designSystem["Component/button"]?.boxShadow?.button?.default,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            hovered: createTokenData(
              designSystem["Component/button"]?.color.background?.button
                ?.default?.hovered,
              designSystem["Component/button"]?.color?.text?.button?.default
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.color?.border?.button?.default
                ?.hovered,
              designSystem["Component/button"]?.boxShadow?.button?.default,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            pressed: createTokenData(
              designSystem["Component/button"]?.color.background?.button
                ?.default?.pressed,
              designSystem["Component/button"]?.color?.text?.button?.default
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.default
                ?.pressed,
              designSystem["Component/button"]?.boxShadow?.button.default,
              designSystem["Component/button"]?.borderWidth?.button
            ),
            disabled: createTokenData(
              designSystem["Component/button"]?.color.background?.button
                ?.default?.disabled,
              designSystem["Component/button"]?.color?.text?.button?.default
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.default
                ?.disabled
            ),
          },
        },
        {
          ghost: {
            default: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.ghost
                ?.primary,
              designSystem["Component/button"]?.color?.text?.button?.ghost
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.ghost?.primary
            ),
            hovered: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.ghost
                ?.hovered,
              designSystem["Component/button"]?.color?.text?.button?.ghost
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.ghost?.hovered
            ),
            pressed: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.ghost
                ?.pressed,
              designSystem["Component/button"]?.color?.text?.button?.ghost
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.ghost?.pressed,
              designSystem["Component/button"]?.boxShadow?.button.ghost
            ),
            iconFill: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.ghost
                ?.iconFill,
              designSystem["Component/button"]?.color?.text?.button?.ghost
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.ghost?.primary
            ),
            disabled: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.ghost
                ?.disabled,
              designSystem["Component/button"]?.color?.text?.button?.ghost
                ?.disabled,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.ghost?.disabled
            ),
          },
        },
        {
          stroke: {
            default: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.stroke
                ?.primary,
              designSystem["Component/button"]?.color?.text?.button?.stroke
                ?.primary,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.stroke?.primary
            ),
            hovered: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.stroke
                ?.hovered,
              designSystem["Component/button"]?.color?.text?.button?.stroke
                ?.hovered,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.stroke?.hovered
            ),
            pressed: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.stroke
                ?.pressed,
              designSystem["Component/button"]?.color?.text?.button?.stroke
                ?.pressed,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.stroke?.hovered,
              designSystem["Component/button"]?.boxShadow?.button?.stroke
            ),
            disabled: createTokenData(
              designSystem["Component/button"]?.color.background?.button?.stroke
                ?.disabled,
              designSystem["Component/button"]?.color?.text?.button?.stroke
                ?.disabled,
              designSystem["Component/button"]?.spacing?.button.inset,
              designSystem["Component/button"]?.borderRadius?.button,
              designSystem["Component/button"]?.font?.button?.uitext,
              designSystem["Component/button"]?.border?.button?.stroke?.disabled
            ),
          },
        },
        {
          link: {
            default: createTokenData(
              "",
              designSystem["Component/link"]?.color?.text?.button?.link
                ?.primary,
              "",
              "",
              designSystem["Component/link"]?.font?.button?.link?.uitext,
              "",
              "",
              "",
              designSystem["Component/link"]?.font?.button?.link?.textdecoration
            ),
            hovered: createTokenData(
              designSystem["Component/link"]?.color.background?.link?.link
                ?.hovered,
              designSystem["Component/link"]?.color?.text?.link?.link?.hovered,
              designSystem["Component/link"]?.spacing?.link.inset,
              designSystem["Component/link"]?.borderRadius?.link,
              designSystem["Component/link"]?.font?.link?.uitext,
              designSystem["Component/link"]?.border?.link?.link?.hovered
            ),
            pressed: createTokenData(
              designSystem["Component/link"]?.color.background?.link?.link
                ?.pressed,
              designSystem["Component/link"]?.color?.text?.link?.link?.pressed,
              designSystem["Component/link"]?.spacing?.link.inset,
              designSystem["Component/link"]?.borderRadius?.link,
              designSystem["Component/link"]?.font?.link?.uitext,
              designSystem["Component/link"]?.border?.link?.link?.hovered
            ),
            disabled: createTokenData(
              designSystem["Component/link"]?.color.background?.link?.link
                ?.disabled,
              designSystem["Component/link"]?.color?.text?.link?.link?.disabled,
              designSystem["Component/link"]?.spacing?.link.inset,
              designSystem["Component/link"]?.borderRadius?.link,
              designSystem["Component/link"]?.font?.link?.uitext,
              designSystem["Component/link"]?.border?.link?.link?.disabled
            ),
          },
        },
      ];
      setTokensData(data);
    }
  }, [designSystem]);

  return tokensData;
};

export default useTokensData;
