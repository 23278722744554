import { trimmedCode } from "helpers/code";

export const toggleCode = (data, withText = false) => {
  return trimmedCode(`<label class="dss-toggler dss-toggler-${data.size}">
    <div class="dss-toggler-elem">
        <input class="dss-toggler-toggler-input" type="checkbox" checked="" ${data.disabled ? "disabled" : ""}/>
    </div>
    ${withText ? `<span class="dss-toggler-text dss-font-toggle-uitext-${data.size}">Toggle Text</span>` : ""}
</label>`);
};
