import { trimmedCode } from "helpers/code";

export const avatarCode = (data, type) => {
  return trimmedCode(`<div class="avatar-badge">
    <div class="avatar avatar-circle avatar-xl ${type} ">
        <img src="/static/media/avatar.3099a42850710725aa17.png " alt="avatar " class="avatar-image " />
    </div>
    ${
      data?.state === "status"
        ? `<span class="avatar-status "><svg width="11 " height="8 " viewBox="0 0 11 8 " fill="none " xmlns="http://www.w3.org/2000/svg "><path d="M1 3.75L3.83413 6.5L9.5 1 " stroke="white " stroke-width="1.5
    " stroke-linecap="round " stroke-linejoin="round "></path></svg></span>`
        : " "
    } ${data?.state === "presence" ? `<span class="avatar-active "></span>` : " "}
</div>`);
};
export const avatarCodeSilhouette = (data) => {
  return trimmedCode(`<div class="avatar-badge">
      <div class="avatar avatar-circle avatar-xl silhouette">
          <svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M39.882 15.8732C39.882 24.6842 32.8173 31.7493 24 31.7493C15.1857 31.7493 8.11804 24.6842 8.11804 15.8732C8.11804 7.06207 15.1857 0 24 0C32.8173 0 39.882 7.06207 39.882 15.8732ZM24 60C10.9871 60 0 57.885 0 49.7249C0 41.5618 11.0561 39.5218 24 39.5218C37.0159 39.5218 48 41.6368 48 49.7969C48 57.96 36.9439 60 24 60Z" fill="white"></path></svg>
      </div>
      ${
        data?.state === "status"
          ? `<span class="avatar-status"><svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.75L3.83413 6.5L9.5 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>`
          : ""
      } ${data?.state === "presence" ? `<span class="avatar-active"></span>` : ""}
  </div>`);
};
export const avatarCodeTextOnly = (data) => {
  return trimmedCode(`<div class="avatar-badge">
    <div class="avatar avatar-circle avatar-xl textonly">
        <div class="avatar-text">AA</div>
    </div>
    ${
      data?.state === "status"
        ? `<span class="avatar-status"><svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.75L3.83413 6.5L9.5 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>`
        : ""
    } ${data?.state === "presence" ? `<span class="avatar-active"></span>` : ""}
</div>`);
};
export const avatarCodeWithSize = (data) => {
  return trimmedCode(`<div class="component-area-ui d-flex justify-content-center dis-column-gap-12 dis-row-gap-12">
    <div class="avatar-badge">
        <div class="avatar avatar-circle avatar-${data.size} default">
            <img src="/static/media/avatar.3099a42850710725aa17.png" alt="avatar" class="avatar-image" /></div>
    </div>
    <div class="avatar-badge">
        <div class="avatar avatar-circle avatar-${data.size} silhouette">
            <svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.882 15.8732C39.882 24.6842 32.8173 31.7493 24 31.7493C15.1857 31.7493 8.11804 24.6842 8.11804 15.8732C8.11804 7.06207 15.1857 0 24 0C32.8173 0 39.882 7.06207 39.882 15.8732ZM24 60C10.9871 60 0 57.885 0 49.7249C0 41.5618 11.0561 39.5218 24 39.5218C37.0159 39.5218 48 41.6368 48 49.7969C48 57.96 36.9439 60 24 60Z"
                fill="white"></path>
            </svg>
        </div>
    </div>
    <div class="avatar-badge">
        <div class="avatar avatar-circle avatar-${data.size} textonly">
            <div class="avatar-text">AA</div>
        </div>
    </div>
</div>`);
};
