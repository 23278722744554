import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import { getCssVariableValue } from "helpers/style";

function ShadowTokenModal({
	isShadowTokenModalOpen,
	setShadowTokenModalOpen,
	handleApplyShadowChange,
	buttonState,
	selectedClassName,
	spaceVValue,
	spaceHValue,
	elevationData,
	boxShadowStyle,
	selectedShadow,
	setSelectedShadow,
}) {
	return (
		<CommonModal
			isOpen={isShadowTokenModalOpen}
			onRequestClose={() => setShadowTokenModalOpen(false)}
			title="Change token"
			onApply={() => handleApplyShadowChange()}
			onCancelText="Cancel"
			onApplyText="Apply"
			// variant="modal-tertiary"
		>
			<div className="plt-modal-body-content">
				<div className="plt-preview dis-mb-32" style={{ fontFamily: buttonState }}>
					<PrimaryButton
						// onClick={() => {}}
						text={"Button text"}
						className={`pressed  ${buttonState} ${selectedClassName} d-flex m-auto`}
						disabled={buttonState === "disabled"}
						style={{
							padding: `${spaceVValue.value}  ${spaceHValue.value}`,
							boxShadow: boxShadowStyle,
						}}
					/>
				</div>

				<div className="token-list dss-custom-radio-group flex-column">
					{Object.entries(elevationData)
						?.slice(0, 6)
						.map(([key, item]) => {
							return (
								<div key={key} onClick={() => setSelectedShadow(item)} className="token-item">
									<label
										className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default  ${
											selectedShadow?.key === item?.key ? "checked" : ""
										}`}
									>
										<input
											className="dss-radio dss-radio-input dss-radio-default"
											name="radio-group"
											type="radio"
											id={item.id}
											value={item.value}
											checked={selectedShadow?.key === item?.key}
										/>
										<div className="dss-radio-label" htmlFor={item?.key}>
											<p className="plt-badge plt-body-sm">{item?.tokenName}</p>
										</div>
									</label>
									<div className="token-info dis-ml-28">
										<p className="d-flex plt-body-compact-sm plt-font-color-primary dis-mt-12">
											<span className="plt-body-compact-sm plt-font-color-primary dis-mr-16">X: {item?.value?.x}</span>
											<span className="plt-body-compact-sm plt-font-color-primary">Y: {item?.value?.y}</span>
										</p>
										<p className="d-flex plt-body-compact-sm plt-font-color-primary dis-mt-8">
											<span className="plt-body-compact-sm plt-font-color-primary dis-mr-16">
												Spread: {item.value.spread}
											</span>
											<span className="plt-body-compact-sm plt-font-color-primary">Blur: {item?.value?.blur}</span>
										</p>
										<p className="d-flex plt-body-compact-sm plt-font-color-primary dis-mt-12">
											Color: {getCssVariableValue(item?.value?.color)}
										</p>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</CommonModal>
	);
}

export default ShadowTokenModal;
