import React, { useState } from "react";

import { ToogleComponentUsagePrimaryDatas } from "assets/data/data";

import toogleDetail from "assets/images/png/toogleDetail.png";
import toogleDefault from "assets/images/png/toogleDefault.png";
import toogleFocused from "assets/images/png/toogleFocused.png";
import tooglePressed from "assets/images/png/tooglePressed.png";
import toogleDisabled from "assets/images/png/toogleDisabled.png";
import tooglePlacement from "assets/images/png/tooglePlacement.png";
import toogleSpacing from "assets/images/png/toogleSpacing.png";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
  {
    number: 1,
    title: "Track",
    description:
      "The visual pathway along which the toggle handle moves, indicating its current state.",
  },
  {
    number: 2,
    title: "Handle",
    description:
      "The movable part of the toggle that users slide to switch between states.",
  },
  {
    number: 3,
    title: "Action Text",
    description:
      "Briefly describe the toggle’s binary action in three words or less, displayed beside the toggle.",
  },
];

const UsageToogleComponent = () => {
  const tabData = ["Anatomy", "Types of toggle states", "Placement", "Spacing"];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              A toggle is a control used to quickly switch between two states,
              typically "on" and "off." Toggles are designed for binary actions
              that take effect immediately after the user activates them.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={toogleDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map((detail) => (
              <DetailItem
                key={detail.number}
                number={detail.number}
                title={detail.title}
                description={detail.description}
              />
            ))}
          </ul>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[1]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Types of toggle states
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              Text fields are crucial components in user interfaces, allowing
              users to input and manage information seamlessly.
            </p>
          </div>

          {ToogleComponentUsagePrimaryDatas.map((data, index) => (
            <div key={index} className="dis-mb-40">
              <div className="plt-content-wrapper dis-mb-24">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                  {data.title}
                </h6>
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                  {data.description}
                </p>
              </div>
              <div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_1}
                  </h4>
                  <div className="plt-component-card">
                    <img src={toogleDefault} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_2}
                  </h4>
                  <div className="plt-component-card">
                    <img src={toogleFocused} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_3}
                  </h4>
                  <div className="plt-component-card">
                    <img src={tooglePressed} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_4}
                  </h4>
                  <div className="plt-component-card">
                    <img src={toogleDisabled} alt="userimage" />
                  </div>
                </div>
              </div>

              <p
                className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}
              >
                {data.detail}
              </p>
              {data.detail_list}
            </div>
          ))}

          <div className="dis-mt-40 dis-mb-40">
            <div className="plt-content-wrapper dis-mb-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[2]}
              >
                Placement
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Ensure the text field is left-aligned and properly indented.
                This alignment promotes consistency and enhances readability
                across the user interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={tooglePlacement} alt="userimage" />
            </div>

            <div className="plt-content-wrapper dis-mb-24 dis-mt-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[3]}
              >
                Spacing
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Maintain consistent spacing around the text field to ensure it
                is visually distinct and easily accessible. Adequate spacing
                improves readability and prevents a cluttered interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={toogleSpacing} alt="userimage" />
            </div>
          </div>
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageToogleComponent;
