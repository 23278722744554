import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../../context/GlobalContext";
import { SyncLoader } from "react-spinners";

const RouteLoader = ({ children }) => {
  const {
    isLoading,
    setLoading,
    sheetIsLoading,
    pusherIsLoading,
    setPusherSheetIsLoading,
  } = useGlobalContext();
  const location = useLocation();

  const [pusherIsLoadingTimeout, setPusherIsLoadingTimeout] = useState(null);

  const clearLoaderTimeout = (timeout, setLoadingFunction) => {
    if (timeout) {
      clearTimeout(timeout);
      setLoadingFunction(false);
    }
  };

  useEffect(() => {
    if (pusherIsLoading) {
      const timeout = setTimeout(() => setPusherSheetIsLoading(false), 8000);
      setPusherIsLoadingTimeout(timeout);
    } else {
      clearLoaderTimeout(pusherIsLoadingTimeout, setPusherSheetIsLoading);
    }
  }, [pusherIsLoading]);

  const shouldShowLoader = isLoading || pusherIsLoading || sheetIsLoading;

  return (
    <>
      {children}
      {shouldShowLoader &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                fontSize: "1.5em",
                color: "white",
                zIndex: 10000,
              }}
            >
              <SyncLoader color="#fff" loading={true} />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default RouteLoader;
