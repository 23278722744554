import { trimmedCode } from "helpers/code";

export const tableCode = trimmedCode(`<table class="dss-scale-table">
  <thead>
    <tr>
      <th class="dss-scale-table-header">Size preview</th>
      <th class="dss-scale-table-header">Size token</th>
      <th class="dss-scale-table-header">Size</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="dss-table-cell">128px</td>
      <td class="dss-table-cell">
        <p class="">space-3200</p>
      </td>
      <td class="dss-table-cell">size-128</td>
    </tr>
    <tr>
      <td class="dss-table-cell">112px</td>
      <td class="dss-table-cell">
        <p class="">space-2800</p>
      </td>
      <td class="dss-table-cell">size-112</td>
    </tr>
    <tr>
      <td class="dss-table-cell">96px</td>
      <td class="dss-table-cell">
        <p class="">space-2400</p>
      </td>
      <td class="dss-table-cell">size-96</td>
    </tr>
    <tr>
      <td class="dss-table-cell">80px</td>
      <td class="dss-table-cell">
        <p class="">space-2800</p>
      </td>
      <td class="dss-table-cell">size-80</td>
    </tr>
    <tr>
      <td class="dss-table-cell">64px</td>
      <td class="dss-table-cell">
        <p class="">space-2000</p>
      </td>
      <td class="dss-table-cell">size-64</td>
    </tr>
    <tr>
      <td class="dss-table-cell">24px</td>
      <td class="dss-table-cell">
        <p class="">space-600</p>
      </td>
      <td class="dss-table-cell">size-24</td>
    </tr>
  </tbody>
</table>`);
