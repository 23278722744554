import { generate } from "helpers/antColor";

const hexToRgb = (color) => {
  const hexColor = color?.toUpperCase();

  const rgb = {
    r: parseInt(hexColor?.slice(1, 3), 16),
    g: parseInt(hexColor?.slice(3, 5), 16),
    b: parseInt(hexColor?.slice(5, 7), 16),
  };
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

function rgbaToHex(color) {
  // Extract the RGBA values from the string using a regular expression
  const result = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);

  // Ensure that the result has the correct number of components
  if (!result || result.length !== 5) {
    throw new Error("Invalid RGBA string");
  }

  // Convert the extracted values to integers and float
  const r = parseInt(result[1]);
  const g = parseInt(result[2]);
  const b = parseInt(result[3]);
  const a = parseFloat(result[4]);

  // Ensure the alpha value is a number between 0 and 1
  const alpha = a < 0 ? 0 : a > 1 ? 1 : a;

  // Convert the RGBA values to hexadecimal
  const hexR = r.toString(16).padStart(2, "0");
  const hexG = g.toString(16).padStart(2, "0");
  const hexB = b.toString(16).padStart(2, "0");
  const hexA = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0");

  // Combine the hexadecimal values
  return `#${hexR}${hexG}${hexB}${hexA}`;
}

const generateColorShades = (baseColor, tokenType, theme) => {
  // Use the Ant Design generate function to create the color palette
  const shades = generate(baseColor, theme);
  if (theme === "dark") shades.reverse();

  return shades.map((shade, index) => {
    const shadeValue = (index + 1) * 100; // Generate shade values from 100 to 1000
    return {
      key: `Theme/${theme}.color.${tokenType}.${shadeValue}`,
      value: shade,
      varName: `--dss-color-${tokenType.replace(/\./g, "-")}-${shadeValue}`,
      tokenName: `dss-color-${tokenType.replace(/\./g, "-")}-${shadeValue}`,
    };
  });
};

export { hexToRgb, rgbaToHex, generateColorShades };
