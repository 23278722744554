import { useState } from "react";
import "./CheckboxComponent.scss";

const CheckboxComponent = ({
  variant,
  size,
  value,
  disabled,
  label,
  className,
  onChange,
}) => {
  const [checked, setChecked] = useState(value);

  // const handleCheckboxChange = () => {
  //   setChecked((prevStates) => !prevStates);
  // };

  return (
    <div
      className={`dss-custom-checkbox-wrapper dss-checkbox ${className ? className : ""} `}
    >
      <form>
        <div className={`dss-custom-checkbox ${disabled ? "disabled" : ""}`}>
          <div className={`dss-checkbox-wrapper`}>
            <input
              disabled={disabled}
              checked={checked}
              className={`dss-checkbox dss-checkbox-${variant} dss-checkbox-${size}`}
              type="checkbox"
              name={`${variant}-${size}`}
              id={`${variant}-${size}`}
              onChange={(e) => {
                onChange(e.target.checked);
                setChecked(e.target.checked);
              }}
            />
            {label && (
              <label
                htmlFor={`${variant}-${size}`}
                className={`dss-checkbox-label dss-checkbox-label-${size} dss-checkbox-label-${variant}`}
              >
                {label}
              </label>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CheckboxComponent;
