import React from "react";
import { getBaseColor } from "helpers";
import { useGlobalContext } from "context/GlobalContext";

const colorSectionData = () => {
  const { designSystem, theme } = useGlobalContext();

  return [
    {
      id: 1,
      title: "Primary colors",
      description:
        "This color is primary and is used for main elements and background.",
      colorData: [
        {
          // baseColor: getBaseColor(designSystem.convertedTokens, "primary"),
          baseColor: getBaseColor(designSystem, "primary", theme),
          shades: designSystem.convertedprimaryTokens,
          tokenType: "primary",
        },
      ],
    },
    {
      id: 2,
      title: "Secondary colors",
      description: "This color is secondary and supports the primary color.",
      colorData: [
        {
          baseColor: getBaseColor(designSystem, "secondary", theme),
          shades: designSystem["convertedsecondaryTokens"],
          tokenType: "secondary",
        },
      ],
    },
    {
      id: 3,
      title: "Supporting colors",
      description:
        "Supporting colors used for accents and to complement the primary and secondary palettes.",
      colorData: [
        {
          baseColor: getBaseColor(designSystem, "supportingColor1", theme),
          shades: designSystem["convertedsupportingColor1Tokens"],
          tokenType: "supporting.color1",
        },
        {
          baseColor: getBaseColor(designSystem, "supportingColor2", theme),
          shades: designSystem["convertedsupportingColor2Tokens"],
          tokenType: "supporting.color2",
        },
        {
          baseColor: getBaseColor(designSystem, "supportingColor3", theme),
          shades: designSystem["convertedsupportingColor3Tokens"],
          tokenType: "supporting.color3",
        },
        // {
        //   baseColor: "#FC6F03",
        //   shades: supportingColorShade4,
        //   tokenType: "supporting-color4",
        // },
      ],
    },
    {
      id: 4,
      title: "Semantic color",
      description:
        "This color is primary and is used for main elements and background.",
      colorData: [
        {
          subTitle: "Negative color",
          baseColor: getBaseColor(designSystem, "negative", theme),
          shades: designSystem["convertednegativeTokens"],
          tokenType: "semantics.negative",
        },
        {
          subTitle: "Caution color",
          baseColor: getBaseColor(designSystem, "caution", theme),
          shades: designSystem["convertedcautionTokens"],
          tokenType: "semantics.caution",
        },
        {
          subTitle: "Positive color",
          baseColor: getBaseColor(designSystem, "positive", theme),
          shades: designSystem["convertedpositiveTokens"],
          tokenType: "semantics.positive",
        },
        {
          subTitle: "Informative color",
          baseColor: getBaseColor(designSystem, "informative", theme),
          shades: designSystem["convertedinformativeTokens"],
          tokenType: "semantics.informative",
        },
      ],
    },

    {
      id: 5,
      title: "Neutral colors",
      description: "Neutral colors used for backgrounds, text, and borders.",
      colorData: [
        {
          baseColor: "#706D69",
          shades: designSystem["convertedneutralsTokens"],
          tokenType: "neutrals",
        },
      ],
    },
  ];
};

export default colorSectionData;
