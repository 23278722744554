import React, { useCallback, useState } from "react";

import { useGlobalContext } from "context/GlobalContext";
import CodeMirror from "@uiw/react-codemirror";
import { html as htmlLang } from "@codemirror/lang-html";
import { vscodeLight, vscodeDark } from "@uiw/codemirror-theme-vscode";
import { EditorView } from "@codemirror/view";

import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import CopyButton from "components/Buttons/CopyButton";
import EditButton from "components/Buttons/EditButton";

import "./ComponentCard.scss";

const ComponentCard = ({
  children,
  code,
  htmlContent = ``,
  editClick,
  showCode = false,
  edit = false,
  cssContent = `
  .${code} {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }
`,
}) => {
  const { designSystemMeta, theme } = useGlobalContext();
  const [htmlCode, setHtmlCode] = useState(htmlContent);
  const [cssCode, setCssCode] = useState(cssContent);
  const [activeTab, setActiveTab] = useState("html");
  const codeTheme = theme === "light" ? vscodeLight : vscodeDark;

  const handleHtmlChange = useCallback((value) => {
    setHtmlCode(value);
  }, []);
  // Function to extract className from the htmlCode
  const extractClassName = (html) => {
    const classNameMatch = html.match(/class="([^"]+)"/);
    return classNameMatch ? classNameMatch[1] : "";
  };

  // Update the children with the new className extracted from the updated HTML
  const updatedChildren = React.cloneElement(children, {
    className: extractClassName(htmlCode),
  });

  const setActiveTabHandler = (tab) => {
    setActiveTab(tab);
  };

  // Custom extension to disable copy-paste
  const disableCopyPaste = EditorView.domEventHandlers({
    paste: (event) => {
      event.preventDefault(); // Prevent paste
    },
    cut: (event) => {
      event.preventDefault(); // Prevent cut
    },
  });

  return (
    <div className="component-card">
      <div className="component-area-ui d-flex justify-content-center dis-column-gap-12 dis-row-gap-12">
        {updatedChildren} {/* Render updated children here */}
        {edit && (
          <AuthAction>
            <Permission designSystem={designSystemMeta}>
              <div onClick={editClick} className="component-edit-btn">
                <EditButton />
              </div>
            </Permission>
          </AuthAction>
        )}
      </div>
      {showCode && (
        <div className="component-area-code">
          <div className="tabs">
            <h3 className="component-code-title plt-heading-xs font-color-primary dis-mb-8">
              HTML
            </h3>
          </div>
          <div className="component-area-code-wrapper d-flex">
            {activeTab === "html" && (
              <CodeMirror
                style={{ width: "100%", height: "auto", fontSize: "13px" }}
                value={htmlCode}
                height="75px"
                width="100"
                extensions={[htmlLang(), disableCopyPaste]}
                onChange={handleHtmlChange}
                theme={codeTheme}
                editable={false}
              />
            )}
            <div className="component-copy-btn">
              <CopyButton text={activeTab === "html" ? htmlCode : cssCode} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComponentCard;
