import React from "react";
import { PERMISIONS } from "constants";
import AuthAction from "components/auth/AuthAction";
import EditButton from "components/Buttons/EditButton";
import Tooltip from "components/Tooltip";

const TeamAccessColumn = ({
  data,
  openModal,
  setSelectedData,
  designSystems,
}) => {
  return (
    <div className='asccess-description-block d-flex '>
      <div className='asccess-description-text d-flex'>
        {data.permission ? (
          data?.permission
            ?.filter((perm) => perm.access_id !== 3)
            ?.map((permission, index) => (
              <>
                <div
                  key={index}
                  className='plt-body-sm plt-font-color-secondary'
                >
                  {
                    designSystems.find(
                      (ds) => ds.id === permission.designSystemId
                    )?.design_system_name
                  }
                  <p
                    className='plt-badge plt-ui-text-xs-medium plt-font-color-primary'
                    key={index}
                  >
                    {
                      PERMISIONS.find(
                        (access) => access.value === permission.access_id
                      )?.label
                    }
                  </p>
                </div>
                <AuthAction>
                  <div
                    className='asccess-description-edit dis-ml-12'
                    onClick={() => {
                      openModal();
                      setSelectedData(data);
                    }}
                  >
                    <Tooltip
                      classPrefix='plt' 
                      position='top'
                      arrow='btm-center'
                      label='Edit Access'
                      text=''
                    >
                      <EditButton />
                    </Tooltip>
                  </div>
                </AuthAction>
              </>
            ))
        ) : (
          <span
            className='plt-modal-link plt-ui-text-sm-medium cursor-pointer d-block w-fit'
            onClick={() => {
              openModal();
              setSelectedData(data);
            }}
          >
            Grant access
          </span>
        )}
      </div>
    </div>
  );
};

export default TeamAccessColumn;
