import React from "react";
import { ButtonIconSample, DropdownArrow } from "components/Icons";
const tabComponentData = () => {
	const commonDesc =
		"Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.";
	const tabsData = [
		{
			title: "Normal",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Leading Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					prefix: <ButtonIconSample color="#171717" />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					prefix: <ButtonIconSample color="#171717" />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					prefix: <ButtonIconSample color="#171717" />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Tailing Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					// badge: "New",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
					arrow: true,
				},
				{
					key: 2,
					label: "Tab title 2",
					// badge: "Updated",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
					arrow: true,
				},
				{
					key: 3,
					label: "Tab title 3",
					// badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
					arrow: true,
				},
			],
		},
		{
			title: "Normal With Badge",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Leading Icon And Badge",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Badge And Tailing Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
					arrow: true,
				},
				{
					key: 2,
					label: "Tab title 2",
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
					arrow: true,
				},
				{
					key: 3,
					label: "Tab title 3",
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
					arrow: true,
				},
			],
		},
		{
			title: "Normal With Leading Icon, Badge And Tailing Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
					arrow: true,
				},
				{
					key: 2,
					label: "Tab title 2",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
					arrow: true,
				},
				{
					key: 3,
					label: "Tab title 3",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
					arrow: true,
				},
			],
		},
	];
	const pilltabsData = [
		{
			title: "Normal",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Leading Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					prefix: <ButtonIconSample color="#171717" />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					prefix: <ButtonIconSample color="#171717" />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					prefix: <ButtonIconSample color="#171717" />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Tailing Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					// badge: "New",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					// badge: "Updated",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					// badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Badge",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Leading Icon And Badge",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
				},
			],
		},
		{
			title: "Normal With Badge And Tailing Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
					arrow: true,
				},
				{
					key: 2,
					label: "Tab title 2",
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
					arrow: true,
				},
				{
					key: 3,
					label: "Tab title 3",
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
					arrow: true,
				},
			],
		},
		{
			title: "Normal With Leading Icon, Badge And Tailing Icon",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 1</div>,
					arrow: true,
				},
				{
					key: 2,
					label: "Tab title 2",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 2</div>,
					arrow: true,
				},
				{
					key: 3,
					label: "Tab title 3",
					prefix: <ButtonIconSample color="#171717" />,
					badge: "15",
					suffix: <DropdownArrow />,
					content: <div class="dss-font-color-primary dss-ui-text-md dis-pt-10">Content 3</div>,
					arrow: true,
				},
			],
		},
	];
	const previewtabsData = [
		{
			title: "Normal",
			desc: commonDesc,
			dataItem: [
				{
					key: 1,
					label: "Tab title 1",
					content: <div className="dss-body-sm">Content 1</div>,
				},
				{
					key: 2,
					label: "Tab title 2",
					content: <div className="dss-body-sm">Content 2</div>,
				},
				{
					key: 3,
					label: "Tab title 3",
					content: <div className="dss-body-sm">Content 3</div>,
				},
			],
		},
	];
	return { tabsData, pilltabsData, previewtabsData };
};

export default tabComponentData;
