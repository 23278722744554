import React from "react";
import { ToastContainer } from "react-toastify";
import SideNavTabs from "components/DesignSystemPage/SideNavTabs";
import { getUser } from "hooks/storageUtils";
import { PrimaryLogo } from "components/Icons";
import SettingsHeader from "./SettingsHeader";

const SuperAdminLayout = ({ children, sideTabs, title = "Admin settings" }) => {
	const user = getUser();

	const defaultLogo = {
		component: <PrimaryLogo />,
		text: "Design",
	};

	const defaultSideTabs = [
		{ title: "Settings", link: `/admin` },
		{ title: "Plans", link: `/admin/plans` },
		{ title: "Subscriptions", link: `/admin/subscriptions` },
		{ title: "Invoices", link: `/admin/invoices` },
	];

	return (
		<div className="dss-page d-flex h-full">
			<ToastContainer />
			<SideNavTabs tabs={defaultSideTabs} logo={defaultLogo} title={title} />
			<div className="tab-content-body w-100vw">
				<SettingsHeader user={user} />
				{children}
			</div>
		</div>
	);
};

export default SuperAdminLayout;
