import { trimmedCode } from "helpers/code";

export const avatarGroupCode = (data) => {
  return trimmedCode(`<div class="avatar-group">
    <div class="avatar avatar-circle avatar-${data.size} dafault avatar-group-count">
        3+
    </div>
    <div class="avatar avatar-circle avatar-${data.size} dafault">
        <img src="/static/media/avatar.3099a42850710725aa17.png" alt="avatar" class="avatar-image" />
    </div>
    <div class="avatar avatar-circle avatar-${data.size} dafault">
        <img src="/static/media/avatar.3099a42850710725aa17.png" alt="avatar" class="avatar-image" />
    </div>
    <div class="avatar avatar-circle avatar-${data.size} dafault">
        <img src="/static/media/avatar.3099a42850710725aa17.png" alt="avatar" class="avatar-image" />
    </div>
    <div class="avatar avatar-circle avatar-${data.size} dafault">
        <img src="/static/media/avatar.3099a42850710725aa17.png" alt="avatar" class="avatar-image" />
    </div>
</div>`);
};
