import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import { useGlobalContext } from "context/GlobalContext";

function RadiusTokenModal({
	isRadiusTokenModalOpen,

	setRadiusTokenModalOpen,
	buttonState,
	selectedClassName,
	setSelectedRadius,
	handleRadiusChange,
	handleApplyRadiusChange,
	selectedRadius,
	radiusValues,
}) {
	const { designSystem } = useGlobalContext();
	return (
		<CommonModal
			isOpen={isRadiusTokenModalOpen}
			onRequestClose={() => {
				setSelectedRadius({
					key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
				});
				setRadiusTokenModalOpen(false);
			}}
			title="Change token"
			onApply={() => handleApplyRadiusChange()}
			onCancelText="Cancel"
			onApplyText="Apply"
		>
			<div className="plt-modal-body-content">
				<div className="modal-token-wrapper">
					<div className="plt-preview dis-mb-32">
						<PrimaryButton
							// onClick={() => {}}
							text={"Button text"}
							className={` ${buttonState} ${selectedClassName}  d-flex m-auto`}
							style={{ borderRadius: selectedRadius?.value }}
						/>
					</div>

					<div className="token-list dss-custom-radio-group flex-column">
						{radiusValues &&
							radiusValues.map((item) => (
								<div key={item.id} className="token-item">
									<label
										className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
											selectedRadius?.key === item.key ? "checked" : ""
										}`}
									>
										<input
											className="dss-radio dss-radio-input"
											name="radio-group"
											type="radio"
											id={item.id}
											value={item.value}
											onChange={() => handleRadiusChange(item)}
											checked={selectedRadius?.key === item.key}
										/>
										<div className="dss-radio-label dss-body-compact-md" htmlFor={item.id}>
											<p className="plt-badge plt-body-sm">{item.label}</p>
										</div>
									</label>
									<div className="token-info dis-ml-28">
										<p className="plt-body-compact-sm plt-font-color-primary dis-mt-12">{item.value}</p>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</CommonModal>
	);
}

export default RadiusTokenModal;
