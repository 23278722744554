import { trimmedCode } from "helpers/code";

export const radioCode = (data, variant) => {
  return trimmedCode(`<div class="dss-custom-radio-group dss-custom-radio-group-${data.size}">
    <label class="dss-radio-wrapper checked dss-radio-${variant} ${data.hasLabel ? "with-label" : " "} ${data.disabled ? " disabled " : " "}">
        <input class="dss-radio-input" type="radio" name="radioGroup-0" value="option1" checked="" />
        ${data.hasLabel ? `<span class="dss-radio-label dss-radio-label-${variant}">Option 1</span>` : ""}
    </label>
    <label class="dss-radio-wrapper dss-radio-${variant} ${data.disabled ? " disabled " : " "}">
        <input class="dss-radio-input" type="radio" name="radioGroup-0" value="option2" />
        ${data.hasLabel ? `<span class="dss-radio-label dss-radio-label-${variant}">Option 2</span>` : ""}
    </label>
</div>`);
};
