import React, { useState } from "react";
import "./Tooltip.scss";

const Tooltip = ({ text = "This is tooltip", children, label, wrap, size, arrow, position, classPrefix }) => {
	const [showTooltip, setShowTooltip] = useState(false);

	const handleMouseEnter = () => {
		setShowTooltip(true);
	};

	const handleMouseLeave = () => {
		setShowTooltip(false);
	};

	return (
		<div
			className={`dss-tooltip-container dss-ui-text-md dss-font-color-primary ${classPrefix}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
			{showTooltip && (
				<div
					className={`dss-tooltip ${position ? "dss-tooltip-" + position : ""} ${arrow ? "dss-tooltip-" + arrow : ""} ${size ? "dss-tooltip-" + size : ""} ${label ? "dss-tooltip-with-label" : ""} ${wrap ? "dss-tooltip-wrap" : ""}`}
				>
					<h6 className="dss-tooltip-label">{label}</h6>
					<h4 className="dss-tooltip-text">{text}</h4>
				</div>
			)}
		</div>
	);
};

export default Tooltip;
