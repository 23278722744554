import React, { useState } from "react";

import paginationDetail from "assets/images/png/paginationDetail.png";
import PaginationPlacement from "assets/images/png/PaginationPlacement.png";
import PaginationSpacing from "assets/images/png/PaginationSpacing.png";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import GuidelineCardPagination from "components/Common/Guidelines/GuidelineCardPagination";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
  {
    number: 1,
    title: "Previous Button",
    description: "Navigate the user to the preceding page, respectively.",
  },
  {
    number: 2,
    title: "Selected Page Button",
    description: "Indicates the current page the user is viewing.",
  },
  {
    number: 3,
    title: "Unselected Page Button",
    description: "Represents a page that the user can navigate to.",
  },
  {
    number: 4,
    title: "Overflow Button",
    description:
      "Dropdown or icon revealing additional hidden pages due to limited space.",
  },
  {
    number: 5,
    title: "Next Button",
    description: "Navigate the user to the following page, respectively.",
  },
];

const UsagePaginationComponent = () => {
  const tabData = ["Anatomy", "Placement", "Spacing", "Guidelines"];

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              The core structure of pagination, including its key components
              like page numbers, navigation buttons, and indicators. These
              elements work together to create a seamless and intuitive user
              experience.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={paginationDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map((detail) => (
              <DetailItem
                key={detail.number}
                number={detail.number}
                title={detail.title}
                description={detail.description}
              />
            ))}
          </ul>

          <div className="dis-mt-40 dis-mb-40">
            <div className="plt-content-wrapper dis-mb-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[1]}
              >
                Placement
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Ensure the text field is left-aligned and properly indented.
                This alignment promotes consistency and enhances readability
                across the user interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={PaginationPlacement} alt="userimage" />
            </div>

            <div className="plt-content-wrapper dis-mb-24 dis-mt-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[2]}
              >
                Spacing
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Maintain consistent spacing around the text field to ensure it
                is visually distinct and easily accessible. Adequate spacing
                improves readability and prevents a cluttered interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={PaginationSpacing} alt="userimage" />
            </div>
          </div>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
              ref={sectionRefs.current[3]}
            >
              Guidelines
            </h6>
            <ul className="plt-detail-list dis-mt-12">
              <li className="plt-body-md plt-font-color-primary">
                Position labels to the left of dropdown fields, aligning with
                the top edge for clarity.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Maintain consistent spacing between labels and dropdown fields
                to ensure balance and readability.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Align labels with the top of the dropdown fields for a clean and
                organized look.
              </li>
              <li className="plt-body-md plt-font-color-primary">
                Ensure labels are clearly linked to their corresponding dropdown
                fields to enhance usability.
              </li>
            </ul>
          </div>

          <GuidelineCardPagination />
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsagePaginationComponent;
