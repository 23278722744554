const prefixSuffixIcon = `<svg
width="24"
height="24"
viewBox="0 0 24 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
color="black"
>
<rect
  x="4.75"
  y="4.75"
  width="14.3581"
  height="14.3581"
  stroke="black"
  stroke-width="1.5"
></rect>
<path d="M5.5 5.5L18.3615 18.3615" stroke="black" stroke-width="1.5"></path>
<path d="M18.3563 5.5L5.5 18.3563" stroke="black" stroke-width="1.5"></path>
</svg>`;

const closeIcon = `<svg
  width="8"
  height="8"
  viewBox="0 0 8 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.66484 1.33667L1.33855 6.6612"
    stroke="#434343"
    stroke-width="1.5"
    stroke-linecap="round"
  ></path>
  <path
    d="M6.66666 6.66671L1.33333 1.33337"
    stroke="#434343"
    stroke-width="1.5"
    stroke-linecap="round"
  ></path>
</svg>`;

const statusIcon = `<svg
  width="16"
  height="17"
  viewBox="0 0 16 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8 0.5C3.58862 0.5 0 4.08862 0 8.5C0 12.9114 3.58862 16.5 8 16.5C12.4114 16.5 16 12.9114 16 8.5C16 4.08862 12.4114 0.5 8 0.5ZM12.0547 6.80469L7.72131 11.1379C7.59131 11.2679 7.42065 11.3334 7.25 11.3334C7.07935 11.3334 6.90869 11.2679 6.77869 11.1379L4.61206 8.97131C4.35132 8.71069 4.35132 8.28931 4.61206 8.02869C4.87268 7.76794 5.29395 7.76794 5.55469 8.02869L7.25 9.724L11.1121 5.86206C11.3727 5.60132 11.7939 5.60132 12.0547 5.86206C12.3153 6.12268 12.3153 6.54395 12.0547 6.80469Z"
    fill="#0F900F"
  ></path>
</svg>`;

const validationIcon = `<svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.666504 5.99996C0.666504 3.05649 3.05584 0.666626 5.99984 0.666626C8.94917 0.666626 11.3332 3.05649 11.3332 5.99996C11.3332 8.94449 8.94917 11.3333 5.99984 11.3333C3.05584 11.3333 0.666504 8.94449 0.666504 5.99996ZM7.96681 7.96317C8.18862 7.7422 8.18862 7.3837 7.96681 7.16208L6.80555 6.00183L7.96681 4.84094C8.18862 4.61997 8.18862 4.25495 7.96681 4.03333C7.745 3.81106 7.38618 3.81106 7.15784 4.03333L6.0031 5.19292L4.84184 4.03333C4.6135 3.81106 4.25468 3.81106 4.03286 4.03333C3.81105 4.25495 3.81105 4.61997 4.03286 4.84094L5.19413 6.00183L4.03286 7.15556C3.81105 7.3837 3.81105 7.7422 4.03286 7.96317C4.14377 8.07398 4.29382 8.13329 4.43735 8.13329C4.5874 8.13329 4.73093 8.07398 4.84184 7.96317L6.0031 6.81009L7.16436 7.96317C7.27527 8.08115 7.4188 8.13329 7.56232 8.13329C7.71238 8.13329 7.8559 8.07398 7.96681 7.96317Z"
          fill="#CE463D"></path></svg>`;

const prefixCode = `<p class="dss-prefix-text">Prefix</p>`;

const suffixCode = `<p class="dss-suffix-text">Suffix</p>`;

const arrowIcon = `<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 5.66663L8.00004 10.3333L3.33337 5.66663"
      stroke="#171717"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path></svg
>`;
const trimmedCode = (data) =>
  data
    .split("\n")
    .filter((line) => line.trim().length > 0) // Remove empty lines
    .map((line) =>
      line.replace(
        /\bclass="([^"]+)"/g,
        (match, classNames) =>
          `class="${classNames.replace(/\s+/g, " ").trim()}"` // Trim spaces between class names
      )
    )
    .join("\n");

export {
  prefixSuffixIcon,
  closeIcon,
  statusIcon,
  validationIcon,
  prefixCode,
  suffixCode,
  arrowIcon,
  trimmedCode,
};
