import React, { useState, useEffect } from "react";

import { getUser } from "hooks/storageUtils";
import { convertDefaultTokens } from "helpers";
import { fetchDesignSystemById } from "api";
import { loadGoogleFont, loadManualFont } from "helpers/font";

import Sidebar from "components/DesignSystemPage/Sidebar";
import SideNavTabs from "components/DesignSystemPage/SideNavTabs";
import Header from "components/DesignSystemPage/Header/Header";
import { PrimaryLogo } from "components/Icons";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { ToastContainer } from "react-toastify";
import DesignSystemLogo from "components/Common/DesignSystemLogo";
import "./DesignSystemLayout.scss";

const DesignSystemLayout = ({
	children,
	bannerData,
	selectedTab,
	setSelectedTab,
	tabs,
	sideTabs,
	title = "Overview",
	...otherProps
}) => {
	const { id } = useParams();

	const { designSystemMeta, setDesignSystem, theme } = useGlobalContext();

	const apiUrl = process.env.REACT_APP_SHEET_URL;
	const user = getUser();

	const defaultLogo = {
		component: <PrimaryLogo />,
		text: "Design",
	};

	const { isCollapsed } = useGlobalContext();

	const designSystemLogo = {
		component: <DesignSystemLogo designSystem={designSystemMeta} />,
		text: "Design",
	};
	let defaultSideTabs = [
		{ title: "Getting started", link: `/${id}/overview/getting-started` },
		{ title: "Principles", link: `/${id}/overview/principles` },
		{ title: "Content", link: `/${id}/overview/content` },
	];
	const overviewPages =
		designSystemMeta?.pages &&
		designSystemMeta?.pages
			.filter((page) => page.type === "overview")
			.map((page) => {
				defaultSideTabs = [
					...defaultSideTabs,
					{
						title: page.name,
						link: `/${id}/overview/pages/${page.id}`,
						page: page,
					},
				];
			});

	const effectiveSideTabs = sideTabs ? sideTabs : defaultSideTabs;
	const [fonts, setFonts] = useState([]);

	React.useEffect(() => {
		const fetchDesignSystem = async () => {
			try {
				const data = await fetchDesignSystemById(id);
				setFonts(data.fonts);

				let tokens = convertDefaultTokens(data?.tokens, theme);
				setDesignSystem(tokens);
			} catch (error) {
				console.error("Failed to fetch design system", error);
			}
		};

		fetchDesignSystem();
	}, []);

	useEffect(() => {
		fonts.map((font) => {
			if (font.type === "manual") {
				loadManualFont(font);
			} else {
				loadGoogleFont(font.family);
			}
		});
	}, [fonts]);

	return (
		<div className="dss-page d-flex h-full">
			<ToastContainer />
			<Sidebar {...otherProps} />{" "}
			<div className="dss-container d-flex flex-fit h-full">
				<SideNavTabs
					tabs={effectiveSideTabs}
					logo={designSystemMeta?.logo ? designSystemLogo : defaultLogo}
					title={title}
				/>
				<div className={`tab-content-body w-100vw ${isCollapsed ? "active" : ""}`}>
					<Header
						bannerData={bannerData}
						tabs={tabs}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						title={title.toLowerCase()}
						setBannerImage={otherProps.setBannerImage}
					/>
					{children}
				</div>
			</div>
		</div>
	);
};

export default DesignSystemLayout;
