import { trimmedCode } from "helpers/code";

export const alertCode = (data) => {
  return trimmedCode(`<div class="dss-alert dss-${data.alerttype} w-100">
  <div class="dss-alert-contents-wrapper">
    <div class="dss-alert-contents-info">
      <div class="dss-alert-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#171717">
          <rect x="4.75" y="4.75" width="14.3581" height="14.3581" stroke="#171717" stroke-width="1.5"></rect>
          <path d="M5.5 5.5L18.3615 18.3615" stroke="#171717" stroke-width="1.5"></path>
          <path d="M18.3563 5.5L5.5 18.3563" stroke="#171717" stroke-width="1.5"></path>
        </svg></div>
      <div class="dss-alert-msg-container dss-alert-action-footer">
        <h6 class="dss-alert-msg">Informative</h6>
        <p class="dss-alert-desc">This is an info alert. It also has longer text to see what these alerts can look like when broken into multiple lines. This one will definitely break into multiple lines in most standard screen resolutions.</p>
      </div>
    </div>
    <div class="d-flex dss-alert-btn-group"><button class="dss-btn dss-btn-primary dss-btn-sm     " type="button">button </button><button class="dss-btn dss-btn-outlined dss-btn-sm     " type="button">button </button></div>
  </div><span class="dss-alert-close-icon"><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66484 1.33667L1.33855 6.6612" stroke="#434343" stroke-width="1.5" stroke-linecap="round"></path>
      <path d="M6.66666 6.66671L1.33333 1.33337" stroke="#434343" stroke-width="1.5" stroke-linecap="round"></path>
    </svg></span>
</div>`);
};
