import React, { useState } from "react";

import { TagUsagePrimaryDatas } from "assets/data/data";

import TagDetail from "assets/images/png/TagDetail.png";
import ChipStyle from "assets/images/png/ChipStyle.png";
import tagDefault from "assets/images/png/tagDefault.png";
import tagHovered from "assets/images/png/tagHovered.png";
import tagPressed from "assets/images/png/tagPressed.png";
import tagDisabled from "assets/images/png/tagDisabled.png";
import tagPlacement from "assets/images/png/tagPlacement.png";
import tagSpacing from "assets/images/png/tagSpacing.png";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
  {
    number: 1,
    title: "Container",
    description: "Holds and defines the boundaries of the chip tag element.",
  },
  {
    number: 2,
    title: "Text",
    description: "Displays the label or content within the chip tag.",
  },
];

const UsageTagComponent = () => {
  const tabData = [
    "Anatomy",
    "Chip styles",
    "Types of chip states",
    "Placement",
    "Spacing",
  ];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              The text input component includes a label and an input field,
              available in two types: Default, with a fixed width for
              consistency, and Fluid, which expands to fit available space for
              adaptability across layouts.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={TagDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map((detail) => (
              <DetailItem
                key={detail.number}
                number={detail.number}
                title={detail.title}
                description={detail.description}
              />
            ))}
          </ul>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[1]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Chip styles
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              The tooltip container can be aligned to start, center, or end to
              prevent it from bleeding off the page or covering important
              information. Tooltip content can be styled to truncate, wrap, or
              fit within a short container, depending on the design needs.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center dis-p-0 dis-mb-40">
            <img src={ChipStyle} alt="userimage" />
          </div>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[2]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Types of chip states
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              Text fields are crucial components in user interfaces, allowing
              users to input and manage information seamlessly.
            </p>
          </div>

          {TagUsagePrimaryDatas.map((data, index) => (
            <div key={index} className="dis-mb-40">
              <div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_1}
                  </h4>
                  <div className="plt-component-card">
                    <img src={tagDefault} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_2}
                  </h4>
                  <div className="plt-component-card">
                    <img src={tagHovered} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_3}
                  </h4>
                  <div className="plt-component-card">
                    <img src={tagPressed} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_4}
                  </h4>
                  <div className="plt-component-card">
                    <img src={tagDisabled} alt="userimage" />
                  </div>
                </div>
              </div>

              <p
                className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}
              >
                {data.detail}
              </p>
              {data.detail_list}
            </div>
          ))}

          <div className="dis-mt-40 dis-mb-40">
            <div className="plt-content-wrapper dis-mb-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[3]}
              >
                Placement
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Ensure the text field is left-aligned and properly indented.
                This alignment promotes consistency and enhances readability
                across the user interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={tagPlacement} alt="userimage" />
            </div>

            <div className="plt-content-wrapper dis-mb-24 dis-mt-24">
              <h6
                className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
                ref={sectionRefs.current[4]}
              >
                Spacing
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Maintain consistent spacing around the text field to ensure it
                is visually distinct and easily accessible. Adequate spacing
                improves readability and prevents a cluttered interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={tagSpacing} alt="userimage" />
            </div>
          </div>
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageTagComponent;
