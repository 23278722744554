import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";

import { getComponentsSideData } from "assets/data/sideTabData";
import { emptystateTokenTabData } from "assets/data/tokenTabData";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import PreviewEmptystateComponent from "components/DesignSystemPage/EmptystateComponent/PreviewEmptystateComponent";
import UsageEmptystateComponent from "components/DesignSystemPage/EmptystateComponent/UsageEmptystateComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";
import "assets/scss/pages/_DesignSystemComponents.scss";

const EmptyStateComponents = () => {
  const { id } = useParams();
  const { designSystemMeta, designSystem } = useGlobalContext();

  const tabData = ["Default"];
  const tabs = ["Preview", "Usage", "Tokens"];

  const componentName = "emptystate";
  const componentKey = `Component/${componentName}`;
  const componentData = designSystem[componentKey];
  const tokenTableData = emptystateTokenTabData(componentData, componentName);

  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const bannerData = {
    heading: "Empty State",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewEmptystateComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      case 1:
        return <UsageEmptystateComponent />;
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;

      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title="Components"
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default EmptyStateComponents;
