import { trimmedCode } from "helpers/code";

export const checkboxCode = (data, variant, disabled = false) => {
  return trimmedCode(`<div class="dss-custom-checkbox ${disabled ? " disabled " : " "}">
    <div class="dss-checkbox-wrapper">
        <input class="dss-checkbox dss-checkbox-${variant} dss-checkbox-${data.size}" type="checkbox" name="${variant}-${data.size} " id="${variant}-${data.size} " checked=" " ${disabled ? " disabled " : " "} />
        <label for="${variant}-${data.size} " class="dss-checkbox-label dss-checkbox-label-${data.size} dss-checkbox-label-${variant} ">Check box label</label>
    </div>
</div>`);
};
