import React, { useEffect } from "react";
import AuthAction from "components/auth/AuthAction";
import PrimaryButton from "../PrimaryButton";

const PublishAlert = ({ show, handleCancel, handlePublish, shouldShowPublishSpan }) => {
	useEffect(() => {
		if (show) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}

		return () => {
			document.body.style.overflow = "";
		};
	}, [show]);

	if (!show) return null;

	return (
		<div className="floating-set">
			<div className="floating-set-wrapper d-flex align-items-center">
				<p className="plt-body-md plt-font-color-light">Do you want to publish this change?</p>
				{/* <button
          className="dss-btn dss-btn-secondary dss-ui-text-md-medium dis-mr-8"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </button> */}
				<div className="floating-set-button-wrapper d-flex align-items-center">
					<PrimaryButton
						onClick={handleCancel}
						text="Cancel"
						variant="secondary"
						size="md"
						icon={false}
						fluid={false}
						className="dis-mr-8"
					/>
					<AuthAction>
						{/* <button
            className='home-header-button cursor-pointer dss-btn dss-btn-primary dss-ui-text-md-medium'
            type='button'
            onClick={handlePublish}
            disabled={!shouldShowPublishSpan}
          >
            Publish
          </button> */}
						<PrimaryButton
							onClick={handlePublish}
							text="Publish"
							variant="primary"
							size="md"
							icon={false}
							fluid={false}
							disabled={!shouldShowPublishSpan}
							className="home-header-button cursor-pointer"
						/>
					</AuthAction>
				</div>
			</div>
		</div>
	);
};

export default PublishAlert;
