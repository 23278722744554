import {
  prefixSuffixIcon,
  closeIcon,
  statusIcon,
  trimmedCode,
} from "helpers/code";

export const tagCode = (data, type, size, label) => {
  return trimmedCode(`<div class="dss-tag ${type ? `dss-tag-${type}` : " "} ${size ? `dss-tag-${size}` : " "} dss-tag-spacing-${data.varient} ${data.mode == "closable " ? "dss-tag-closable " : " "} ${data.disable ? "disabled " : " "}">

    ${
      data.prefixIcon
        ? `<div class="dss-tag-leading">
        <span class="dss-prefix-icon">
        ${prefixSuffixIcon}</span>
</div>`
        : ""
    }
    ${
      data.statusIcon
        ? `<div class="dss-tag-leading">
        <span class="dss-prefix-icon">
        ${statusIcon}</span>
</div>`
        : ""
    }
    <span class="dss-tag-label">${label ? label : "Basic tag"}</span>
    ${
      data.mode === "closable"
        ? ` <span class="dss-close-icon">
    ${closeIcon}</span>`
        : ""
    }
   ${data.mode === "counter" ? `<span class="dss-tag-counter">| 0</span>` : ""}
</div>`);
};
