import React, { useState } from "react";

import { useGlobalContext } from "context/GlobalContext";

import { AvatarUsagePrimaryDatas } from "assets/data/data";

import AvatarDetail from "assets/images/png/AvatarDetail.png";
import AvatarDefault from "assets/images/png/AvatarDefault.png";
import AvatarSilhouette from "assets/images/png/AvatarSilhouette.png";
import AvatarText from "assets/images/png/AvatarText.png";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
  {
    number: 1,
    title: "Body",
    description: "The picture representing the user or entity.",
  },
  {
    number: 2,
    title: "Status",
    description:
      "A symbol indicating the avatar's status, positioned in the top-right corner of the avatar component.",
  },
  {
    number: 3,
    title: "Presence",
    description:
      "Shows whether a user is available, away, focused, or busy. It is located at the bottom left of the avatar component.",
  },
];

const UsageAvatarComponent = () => {
  const { isLoading } = useGlobalContext();
  const tabData = ["Anatomy", "Types of Avatar"];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              The avatar component consists of three elements: Body, status, and
              presence.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={AvatarDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map((detail) => (
              <DetailItem
                key={detail.number}
                number={detail.number}
                title={detail.title}
                description={detail.description}
              />
            ))}
          </ul>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[1]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Types of Avatar
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              Text fields are crucial components in user interfaces, allowing
              users to input and manage information seamlessly.
            </p>
          </div>

          {AvatarUsagePrimaryDatas.map((data, index) => (
            <div key={index} className="dis-mb-40">
              <div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_1}
                  </h4>
                  <div className="plt-component-card">
                    <img src={AvatarDefault} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_2}
                  </h4>
                  <div className="plt-component-card">
                    <img src={AvatarSilhouette} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
                    {data.sub_title_3}
                  </h4>
                  <div className="plt-component-card">
                    <img src={AvatarText} alt="userimage" />
                  </div>
                </div>
              </div>

              <p
                className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}
              >
                {data.detail}
              </p>
              {data.detail_list}
            </div>
          ))}
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageAvatarComponent;
